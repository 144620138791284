import { CREATE_DATAVISUALIZATION_DETAILS_NEW, GET_ALL_DATAVISUALIZATION_DETAILS_NEW, UPDATE_DATAVISUALIZATION_DETAILS_NEW } from '../../common/DataVisualization/DataVisualizationAction'
import {
	GET_REPORTS,
	SAVE_REPORTS,
	DELETE_REPORTS,
	SET_TABLE_TYPE,
	GET_REPORTS_DATA_LAYOUTS_SUCCESS,
	UPDATE_REPORTS_DATA_LAYOUTS_SUCCESS,
	
} from './reportActions'

export interface Reports {
	reportsLoading: boolean
	reports: any
	selectedTableName: any
}

const initialState: Reports = {
	reportsLoading: false,
	reports: [],
	selectedTableName: {type: 'Portfolio'},
}

export function reportsReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_TABLE_TYPE:
			state.selectedTableName.type = action.data
			return {...state}
		case GET_REPORTS.triggered:
			return {...state}
		case GET_REPORTS.failed:
			return {...state}
		case GET_REPORTS.succeeded:
			state.reports = action.response.data
			return {
				...state,
			}

		case GET_ALL_DATAVISUALIZATION_DETAILS_NEW.triggered:
		case UPDATE_DATAVISUALIZATION_DETAILS_NEW.triggered:
		case CREATE_DATAVISUALIZATION_DETAILS_NEW.triggered:
			return {...state}

		case GET_ALL_DATAVISUALIZATION_DETAILS_NEW.failed:
		case UPDATE_DATAVISUALIZATION_DETAILS_NEW.failed:
		case CREATE_DATAVISUALIZATION_DETAILS_NEW.failed:
			return {...state}

		case GET_REPORTS_DATA_LAYOUTS_SUCCESS:
			state.reports =
				action.data.response && action.data.response.data && action.data.response.data[0]
					? JSON.parse(action.data.response.data[0].layout.toString())
					: []

			return {
				...state,
			}

		case UPDATE_REPORTS_DATA_LAYOUTS_SUCCESS:
			state.reports =
				action.data.response && action.data.response.data && action.data.response.data.layout
					? JSON.parse(action.data.response.data.layout.toString())
					: []
			
			return {
				...state,
			}

		case SAVE_REPORTS.triggered:
			return {...state}
		case SAVE_REPORTS.failed:
			return {...state}
		case SAVE_REPORTS.succeeded:
			state.reports = action.response.data.items
			return {
				...state,
			}
		case DELETE_REPORTS.triggered:
			return {...state, loading: true}
		case DELETE_REPORTS.failed:
			return {...state, loading: false}
		case DELETE_REPORTS.succeeded:
			state.reports = action.response.data.items
			return {
				...state,
				loading: false,
			}
	}

	return state
}
