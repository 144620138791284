import {globalColumnDefinition} from '../../common/AgGrid/AgGridColumn'
import {commaTwoDecimal, noPinnedFooter} from '../../common/AgGrid/AgGridHelper'

const peerToPeerScaleFactor = 1

export const eachLayout = {
	i: '',
	w: 6,
	h: 6 * peerToPeerScaleFactor,
	x: 0,
	y: 0,
	minW: 4,
	maxW: 12,
	minH: 6 * peerToPeerScaleFactor,
}

export const orderType = {
	repo: 'REPO',
	p2p: 'P2P',
	ficc: 'FICC',
	gcf: 'GCF',
}

const pivotParams = {
	enableRowGroup: true,
	enablePivot: true,
}

const aggFuncSum = {
	aggFunc: 'sum',
}

export const columnDefs: any = renameAllInRateToRepoRate => [
	Object.assign({}, globalColumnDefinition.actions, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.activityType, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.partial, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.portfolioEntryType, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.securityId, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.cusip, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.securityIdType, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.isRestricted, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.inefficienciesArray, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.balanceSheetContributionAmount, {...aggFuncSum}),
	Object.assign({}, globalColumnDefinition.lcrNotionalAmount, {...aggFuncSum}),
	Object.assign({}, globalColumnDefinition.lcrCost, {...aggFuncSum}),
	Object.assign({}, globalColumnDefinition.lcrHaircut, {...aggFuncSum}),
	Object.assign({}, globalColumnDefinition.lcrHqla, {...aggFuncSum}),
	Object.assign({}, globalColumnDefinition.assetBasedCharge, {...aggFuncSum}),
	Object.assign({}, globalColumnDefinition.cofReferenceRate, {
		...aggFuncSum,
		...commaTwoDecimal,
		filter: true,
		filterType: 'number',
		filterParams: {
			valueFormatter: commaTwoDecimal.cellRenderer,
		},
		...noPinnedFooter,
	}),
	Object.assign({}, globalColumnDefinition.quantity, {...aggFuncSum}),
	Object.assign({}, globalColumnDefinition.priceAmount, {...aggFuncSum}),
	Object.assign({}, globalColumnDefinition.priceCurrency, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.priceMultiplier, {...aggFuncSum}),
	Object.assign({}, globalColumnDefinition.notionalCurrency, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.notionalAmount, {...aggFuncSum}),
	globalColumnDefinition.exchangeRate,
	Object.assign({}, globalColumnDefinition.baseNotionalAmount, {...aggFuncSum}),
	Object.assign({}, globalColumnDefinition.recallInefficiencyContributionAmount, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.returnInefficiencyContributionAmount, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.counterParty, {...pivotParams}),
	globalColumnDefinition.haircut,
	Object.assign({}, globalColumnDefinition.collateralType, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.collateralAmount, {...aggFuncSum}),
	Object.assign({}, globalColumnDefinition.referenceRate, {...pivotParams}),
	globalColumnDefinition.spread,
	Object.assign({}, globalColumnDefinition.allInRate, {
		headerName: renameAllInRateToRepoRate ? 'Repo Rate (%)' : 'All In Rate (%)',
	}),
	Object.assign({}, globalColumnDefinition.rebate, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.borrowFee, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.loanFee, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.tradeType, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.buySellInd, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.maturityType, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.startDate, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.endDate, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.maturityDate, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.termDate, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.tradeDate, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.settlementDate, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.orderCreatedBy, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.createdDate, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.fund, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.book, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.sourceSystem, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.sourceSystemTradeId, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.isin, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.ticker, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.originalTradeType, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.legalEntity, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.ric, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.bbId, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.assetClassification, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.assetType, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.assetSubType, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.sector, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.index, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.moodyRating, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.snpRating, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.fitchRating, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.assetMaturityDate, {...pivotParams}),
	Object.assign({}, globalColumnDefinition.poolFactor, {...pivotParams}),
]
