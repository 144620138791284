import {repoTradeDetailsQuery, boxesQueryNew} from '../../../../features/tradeDetails/tradeDetailsQueries'
import {createFetchActions} from '../../../../services/createActions'
import * as axios from 'axios'
import {getPMSBaseUrl, getPMSGraphBaseUrl} from '../../../../services/apiService'
import {getAuthToken} from '../../../../services/authService'
import {fetchSearchSecurityResultQuery} from '../../../abcPortfolio/abcPortfolioQueries'
import {getPMSClient} from '../../../..'
import {post} from '../../../../services/createAPICall'

export const GET_ALL_TRADES = createFetchActions('GET_ALL_TRADES')
export const FETCH_LOCATE_SEARCH_RESULT_FOR_SBL = createFetchActions('FETCH_LOCATE_SEARCH_RESULT_FOR_SBL')
export const SUBMIT_LOAN_FROM_SBL = createFetchActions('SUBMIT_LOAN_FROM_SBL')

export const RESET_ALL_TRADES = 'RESET_ALL_TRADES'

export const resetAllTrades = () => {
	return {type: RESET_ALL_TRADES}
}

export const getAllBoxEntries = type => {
	const query = type && type === 'REPO' ? repoTradeDetailsQuery.boxes : boxesQueryNew
	return function (dispatch) {
		dispatch({type: GET_ALL_TRADES.triggered})
		const instance = axios.default.create({
			baseURL: `${getPMSGraphBaseUrl()}`,
			timeout: 1000000,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${getAuthToken()}`,
			},
		})

		instance.post('', query).then(data => {
			dispatch({
				type: GET_ALL_TRADES.succeeded,
				response: data.data,
				dashboardType: type ? type : null,
			})
		})
	}
}
export const getLocateSearchResultSBL = data => {
	return function (dispatch) {
		dispatch({
			type: FETCH_LOCATE_SEARCH_RESULT_FOR_SBL.triggered,
		})
		getPMSClient()
			.query({
				query: fetchSearchSecurityResultQuery(data),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_LOCATE_SEARCH_RESULT_FOR_SBL.succeeded,
					response,
				})
			})
	}
}
export const submitLoanSBL = (data: any) => (dispatch: any) => {
	return dispatch(post(SUBMIT_LOAN_FROM_SBL, `${getPMSBaseUrl()}/v1/portfolios/new-borrow`, data))
}
