import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {MainState} from '../../store/mainReducer'
import WebSocketNotification from './webSocketComponent'
import {getPortfolioStatus} from '../portfolioStatus/portfolioStatusAction'
import {getTradingQueueData} from '../../pages/tradingQueue/tradingQueueActions'
import {setNotificationData} from '../../features/toastNotification/toastNotificationActions'
import {getPortfolioAllEntries, getPortfolioSelectedEntries, getRestrictedEntries} from '../../app/appActions'
import {getExecutionNotifications, getLocateNotifications, getLocateClientNotifications} from '../../app/appActions'
import {resetDashboardData} from '../../pages/dashboard/dashboardActions'
import {resetAllTrades} from '../../features/tradeDetails/tradeDetailsActions'
import {getBasketDetails} from '../../pages/needsAvailabilities/needsAvailabilitiesActions'
import {setAbcmRerunTiming} from '../../features/abcmStatus/abcmStatusActions'
import {reloadFundCashFlowData} from '../../features/fundCashFlowTitleBar/fundCashFlowTitleBarActions'
import {getAbcPortfolioData} from '../../pages/abcPortfolio/abcPortfolioActions'
import {getCostOfFunds, getCashPositionAndFlow} from '../../features/fundCashFlowTitleBar/fundCashFlowTitleBarActions'
import {fetchLocateAuthorizerDetails} from '../../pages/Locate/LocateAuthorizer/LocateAuthorizerActions'
import {fetchLocateRequesterDetails} from '../../pages/Locate/LocateRequester/LocateRequesterActions'
import {getTargetedInventory} from '../../pages/alp/AlpSttInventoryManager/AlpSttInventoryMgmtActions'
import {updateRowDataAuthorizer} from '../../pages/alp/AlpSttAuthorizer/AlpSttAuthorizerActions'
import {updateRowDataRequester} from '../../pages/alp/AlpSttRequester/AlpSttRequesterActions'
import {getPortfolioDocsAll, getPortfolioDocsIncluded} from '../../pages/portfolioManager/portfolioMangerActions'
import {getKPISummary} from '../widget/DummyWidgetAction'
import {fetchAllSecurities} from '../../pages/alp/AlpNewRequestModal/AlpNewRequestModalActions'

const mapStateToProps = (state: MainState) => {
	return {
		abcPortfolioFilters: state.abcPortfolio.groupFilters,
		pendingAbcmStatus: state.abcmStatus.pendingAbcmStatus,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators(
		{
			getPortfolioDocsIncluded,
			getPortfolioDocsAll,
			getPortfolioStatus,
			setNotificationData,
			getPortfolioAllEntries,
			getPortfolioSelectedEntries,
			resetDashboardData,
			resetAllTrades,
			getRestrictedEntries,
			getBasketDetails,
			setAbcmRerunTiming,
			getAbcPortfolioData,
			getTradingQueueData,
			reloadFundCashFlowData,
			getExecutionNotifications,
			getCostOfFunds,
			getCashPositionAndFlow,
			fetchLocateAuthorizerDetails,
			fetchLocateRequesterDetails,
			getLocateNotifications,
			getLocateClientNotifications,
			getTargetedInventory,
			updateRowDataAuthorizer,
			updateRowDataRequester,
			getKPISummary,
			fetchAllSecurities,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(WebSocketNotification)
