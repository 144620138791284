import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'
import {get} from '../../services/createAPICall'

export const RECENT_TRADE_BLOTTER_ERROR = createFetchActions('RECENT_TRADE_BLOTTER_ERROR')

export const UPLOAD_TRADE_BLOTTER = createFetchActions('UPLOAD_TRADE_BLOTTER')
export const FINALIZE_TRADE_BLOTTER_UPLOAD = createFetchActions('FINALIZE_TRADE_BLOTTER_UPLOAD')
export const DELETE_TRADE = createFetchActions('DELETE_TRADE')
export const GET_TRADES = createFetchActions('GET_TRADES')
export const GET_DEPO_OPTION = createFetchActions('GET_DEPO_OPTION')

export const getTrades = () => dispatch => {
	return dispatch(get(GET_TRADES, `${getPMSBaseUrl()}/v1/tradeBlotter`))
}

export const getDepoOption = () => dispatch => {
	return dispatch(get(GET_DEPO_OPTION, `${getPMSBaseUrl()}/v1/mds/depo`))
}
