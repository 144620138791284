import {createFetchActions} from '../../services/createActions'
import {post} from '../../services/createAPICall'
import { getPMSBaseUrl} from '../../services/apiService'
export const GET_REPORTS = createFetchActions('GET_REPORTS')
export const SAVE_REPORTS = createFetchActions('SAVE_REPORTS')
export const DELETE_REPORTS = createFetchActions('DELETE_REPORTS')
export const SET_TABLE_TYPE = 'SET_TABLE_TYPE'

export const GET_REPORTS_DATA_LAYOUTS_SUCCESS = 'GET_REPORTS_DATA_LAYOUTS_SUCCESS'
export const UPDATE_REPORTS_DATA_LAYOUTS_SUCCESS = 'UPDATE_REPORTS_DATA_LAYOUTS_SUCCESS'

export function setTableType(flag: any) {
	return {
		type: SET_TABLE_TYPE,
		data: flag,
	}
}

export const getReports = user => (dispatch: any) => {
	return dispatch(
		post(GET_REPORTS, `${getPMSBaseUrl()}/getfmcfg`, user, null, 'Error occured while getting reports data')
	)
}

export const getReportsLayoutsSuccess = data => {
	return {
		type: GET_REPORTS_DATA_LAYOUTS_SUCCESS,
		data: data,
	}
}

export const updateReportsLayoutsSuccess = data => {
	return {
		type: UPDATE_REPORTS_DATA_LAYOUTS_SUCCESS,
		data: data,
	}
}

export const saveReports = widget => (dispatch: any) => {
	return dispatch(
		post(SAVE_REPORTS, `${getPMSBaseUrl()}/savefmcfg`, widget, null, 'Error occured while saving reports data')
	)
}



export const deleteReports = user => (dispatch: any) => {
	return dispatch(
		post(DELETE_REPORTS, `${getPMSBaseUrl()}/deletefmcfg`, user, null, 'Error occured while deleting reports data')
	)
}
