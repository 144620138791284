import {ArrayUtil} from 'helper-util'
import {formatWithThreeDecimals} from '../../../utils/formatUtils'

export const rateColumnDef = onRateToggleAuthorizer => {
	return {
		headerName: 'Rebate/Fee',
		field: 'rate',
		headerComponent: 'FeeHeader',
		headerComponentParams: {
			onToggle: onRateToggleAuthorizer,
		},
		headerClass: ['d-flex', 'justify-content-end'],
		type: 'numericColumn',
		width: 160,
		maxWidth: 160,
		minWidth: 150,
	}
}

const fallbackFormatter = (data, skipFormatting = false) => {
	if (data.originalRate === 0 || data.rate === 0 || data.fee === 0) {
		return skipFormatting ? 0 : formatWithThreeDecimals(0)
	}
	return ''
}

export const dataMassagingFn = gridData => {
	return !ArrayUtil.isEmpty(gridData)
		? gridData.map(d => {
				return {
					...d,
					shortfall: d.isPartial ? (d.maxReqQty || 0) - (d.bookedQty || 0) : (d.requestedQty || 0) - (d.bookedQty || 0),
					originalRate: d.rate ? formatWithThreeDecimals(Number(d.rate)) : 0,
					rate: d.rate ? formatWithThreeDecimals(Number(d.rate)) : fallbackFormatter(d),
					feeToggleState: 0,
					requestedQty: d.isPartial ? d.maxReqQty : d.requestedQty,

					// status: 'COUNTER',
				}
		  })
		: []
}

export const alterRateField = (gridData, toggleMode) => {
	return !ArrayUtil.isEmpty(gridData)
		? gridData.map(d => {
				return {
					...d,
					rate:
						toggleMode === 0
							? d.originalRate || fallbackFormatter(d)
							: d.originalRate
							? d.originalRate * 100
							: fallbackFormatter(d, true),
					feeToggleState: toggleMode,
				}
		  })
		: []
}

export const updateRowData = (gridData, rowData) => {
	if (!ArrayUtil.isEmpty(gridData)) {
		const rowIndex = gridData.findIndex(eachRow => eachRow.requestId === rowData.requestId)
		if (rowIndex > -1) {
			gridData[rowIndex] = rowData
		} else {
			gridData.unshift(rowData)
		}
	}
	return gridData || []
}
