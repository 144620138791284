import {buildQuery} from 'axios-graphql-builder'

export const getSecuritySuggestionsQuery = (keyword = '') => {
	const paramList = [
		{
			key: 'securitySuggestions',
			param: `keyword: "${keyword}"`,
		},
	]
	const typeAhead = {
		query: {
			securitySuggestions: {
				securityId: null,
				cusip: null,
				ticker: null,
				assetType: null,
				assetSubtype: null,
				isin: null,
				securityDescription: null,
				availableQuantity: null,
				currency: null,
				cusipCode: null,
				margin: null,
				price: null,
				priceMultiplier: null,
				ric: null,
				schedule: null,
				scheduleDescription: null,
				sedol: null,
				minPiece: null,
				minIncrement: null,
				minLotSize: null,
			},
		},
	}

	return buildQuery(typeAhead, paramList)
}
