import {GET_COLLATERAL_ELIGIBILITY_DATA} from './CollateralEligibilityMatrixActions'

export interface CollateralEligibility {
	collateralEligibilityData: any
	collateralEligibilityDataLoading: boolean
}

const initialState: CollateralEligibility = {
	collateralEligibilityData: [],
	collateralEligibilityDataLoading: false,
}

export function CollateralEligibilityReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_COLLATERAL_ELIGIBILITY_DATA.triggered:
			return {...state, collateralEligibilityData: [], collateralEligibilityDataLoading: true}
		case GET_COLLATERAL_ELIGIBILITY_DATA.failed:
			return {...state, collateralEligibilityData: [], collateralEligibilityDataLoading: false}
		case GET_COLLATERAL_ELIGIBILITY_DATA.succeeded:
			const collateralEligibilityData = action.response.data
			return {...state, collateralEligibilityData, collateralEligibilityDataLoading: false}
	}
	return state
}
