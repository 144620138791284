import {SET_TRADE_BY} from '../dashboard/dashboardActions'
import {
	GET_SBL_PORTFOLIO_ANALYTICS,
	RESET_DASHBOARD_DATA,
	SET_SBL_CLICKED_PORTFOLIO_ENTITY,
	SET_SBL_CLICKED_GROUPBY_ENTITY,
	SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE,
	SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE,
	SET_SBL_TOGGLE_INDEX,
	SET_SBL_DRILLDOWNN,
	GET_EXTERNAL_AVAILABILITIES,
	GET_SBL_DASHBOARD_OVERVIEW_AND_RERATE,
} from './sblActions'

export interface Sbl {
	loading: boolean
	clickedSblGroupByEntity: any
	firstLevelTreemapValue: string
	secondLevelTreemapValue: string
	clickedSblPortfolioEntity: string
	dashboardEntityAmountDataLoading: boolean
	dashboardEntityAmountData: any
	dashboardPortfolioAnalyticsLoading: boolean
	dashboardPortfolioAnalytics: Object
	clickedSblToggleIndex: any
	tradeBy: any
	drilldown: any
	externalAvailabilitiesLoading: boolean
	externalAvailabilityData: any
}

const initialState: Sbl = {
	loading: false,
	clickedSblGroupByEntity: {},
	clickedSblToggleIndex: {},
	firstLevelTreemapValue: '',
	secondLevelTreemapValue: '',
	clickedSblPortfolioEntity: '',
	dashboardEntityAmountDataLoading: false,
	dashboardEntityAmountData: {},
	dashboardPortfolioAnalyticsLoading: false,
	dashboardPortfolioAnalytics: [{}, {}],
	tradeBy: null,
	drilldown: {},
	externalAvailabilitiesLoading: false,
	externalAvailabilityData: [],
}

export function sblReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_SBL_CLICKED_GROUPBY_ENTITY:
			const {groupEntityname, groupOne, groupTwo} = action.response
			const clickedSblGroupByEntity = state.clickedSblGroupByEntity
			clickedSblGroupByEntity[groupEntityname] = [groupOne, groupTwo]
			return {...state, clickedSblGroupByEntity}

		case SET_SBL_TOGGLE_INDEX:
			const {toggleName, index} = action.response
			const clickedSblToggleIndex = state.clickedSblToggleIndex
			clickedSblToggleIndex[toggleName] = index
			return {...state, clickedSblToggleIndex}

		case SET_SBL_CLICKED_PORTFOLIO_ENTITY:
			const clickedSblPortfolioEntity = action.response.data
			return {...state, clickedSblPortfolioEntity: clickedSblPortfolioEntity}
		case SET_SBL_DRILLDOWNN:
			const {drilldownName, drilldownData} = action.response
			const drilldown = {}
			if (drilldownName && drilldownData) drilldown[drilldownName] = drilldownData
			return {...state, drilldown}
		case SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE:
			const firstLevelTreemapValue = action.response.data
			return {...state, firstLevelTreemapValue: firstLevelTreemapValue}

		case SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE:
			const secondLevelTreemapValue = action.response.data
			return {...state, secondLevelTreemapValue: secondLevelTreemapValue}

		case GET_SBL_DASHBOARD_OVERVIEW_AND_RERATE.triggered:
			return {
				...state,
				dashboardEntityAmountData: state.dashboardEntityAmountData,
				dashboardEntityAmountDataLoading: true,
				loading: true,
			}

		case GET_SBL_DASHBOARD_OVERVIEW_AND_RERATE.succeeded:
			const dashboardOverviewRes = action.response.data
			const {dashboardEntityAmountData} = state
			let dashboardEntityAmountDataCopy = !!dashboardEntityAmountData ? dashboardEntityAmountData : {}

			dashboardEntityAmountDataCopy = dashboardEntityAmountDataCopy.hasOwnProperty('sblDashboard')
				? dashboardEntityAmountDataCopy
				: {sblDashboard: {...dashboardOverviewRes}}

			dashboardEntityAmountDataCopy = dashboardEntityAmountDataCopy.sblDashboard.hasOwnProperty('overview')
				? dashboardEntityAmountDataCopy
				: {sblDashboard: {overview: {...dashboardOverviewRes}}}

			dashboardEntityAmountDataCopy.sblDashboard.overview['sblTradesSbl'] = dashboardOverviewRes.loans
			dashboardEntityAmountDataCopy.sblDashboard.overview['sblTradesBorrows'] = dashboardOverviewRes.borrows
			dashboardEntityAmountDataCopy.sblDashboard.overview['loanOpenTrades'] = dashboardOverviewRes.loanOpenTrades
			dashboardEntityAmountDataCopy.sblDashboard.overview['borrowOpenTrades'] = dashboardOverviewRes.borrowOpenTrades
			dashboardEntityAmountDataCopy.sblDashboard.overview['loanReRate'] = dashboardOverviewRes.loanReRate
			dashboardEntityAmountDataCopy.sblDashboard.overview['borrowReRate'] = dashboardOverviewRes.borrowReRate
			dashboardEntityAmountDataCopy.sblDashboard.overview['loanMatureTrades'] = dashboardOverviewRes.loanMatureTrades
			dashboardEntityAmountDataCopy.sblDashboard.overview['borrowMatureTrades'] =
				dashboardOverviewRes.borrowMatureTrades

			return {
				...state,
				dashboardEntityAmountData: dashboardEntityAmountDataCopy,
				dashboardEntityAmountDataLoading: false,
				loading: state.dashboardPortfolioAnalyticsLoading,
			}

		case GET_SBL_PORTFOLIO_ANALYTICS.triggered:
			return {
				...state,
				dashboardPortfolioAnalytics: state.dashboardPortfolioAnalytics,
				dashboardPortfolioAnalyticsLoading: true,
				loading: true,
			}
		case GET_SBL_PORTFOLIO_ANALYTICS.succeeded:
			const {response} = action
			const dashboardPortfolioAnalytics = [
				{...state.dashboardPortfolioAnalytics[0], ...response[0]},
				{...state.dashboardPortfolioAnalytics[1], ...response[1]},
			]

			return {
				...state,
				dashboardPortfolioAnalytics,
				dashboardPortfolioAnalyticsLoading: false,
				loading: state.dashboardEntityAmountDataLoading,
			}

		case RESET_DASHBOARD_DATA:
			return {
				...state,
				dashboardEntityAmountData: {},
				dashboardPortfolioAnalytics: [{}, {}],
				loading: false,
			}

		case SET_TRADE_BY.triggered:
			return {...state, tradeBy: null, loading: true}
		case SET_TRADE_BY.succeeded:
			let tradeBy = JSON.parse(JSON.stringify(state.tradeBy))
			const tradeByList = action.response
			Object.keys(tradeByList).forEach(key => {
				if (key && tradeByList[key]) {
					const tradingQueueSummary = tradeByList[key].tradingQueueSummary
					if (tradeBy === null) tradeBy = {}
					tradingQueueSummary.forEach(summaryDetail => {
						const counterParty = summaryDetail.counterParty
						const userSummaries = summaryDetail.userSummaries
						if (!tradeBy[key]) tradeBy[key] = {}
						let tradeByText = `\n\nMoved by: `
						userSummaries.forEach(summary => {
							tradeByText += `\n${summary.userEmail} (${summary.entriesCount})`
						})
						tradeBy[key][counterParty] = tradeByText
					})
				}
			})
			return {
				...state,
				tradeBy,
				loading: false,
			}
		case SET_TRADE_BY.failed:
			return {...state, loading: false}
		case GET_EXTERNAL_AVAILABILITIES.triggered:
			return {...state, externalAvailabilityData: null, externalAvailabilitiesLoading: true}
		case GET_EXTERNAL_AVAILABILITIES.succeeded:
			return {
				...state,
				externalAvailabilityData: action.response.data.externalAvailabilityNeeds,
				externalAvailabilitiesLoading: false,
			}
	}
	return state
}
