import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'connected-react-router'
import ApolloClient from 'apollo-boost'
import * as Sentry from '@sentry/browser'
import './index.scss'
import App from './app/appContainer'
import * as serviceWorker from './serviceWorker'
import {mainStore, history} from './store/mainStore'
import {
	getMDSGraphBaseUrl,
	getIAMGraphBaseUrl,
	getPMSGraphBaseUrl,
	getMPSGraphBaseUrl,
	getPBMSGraphBaseUrl,
	getLocateGraphBaseUrl,
	getConfigGraphBaseUrl,
	getMarketplaceGraphBaseUrl,
} from './services/apiService'
import {getAuthToken} from './services/authService'
import {gqlErrorHandler} from './clientHelper'
import {getTimeZone} from './services/clientConfigurationService'

const enableSentry = process.env.REACT_APP_ENABLE_SENTRY === 'true'
if (enableSentry) {
	Sentry.init({
		dsn: 'https://81c14098249746e3ad251721b92fbb11@o381261.ingest.sentry.io/5208332',
		environment: process.env.REACT_APP_ENV,
		release: '%REACT_APP_RELEASE_VERSION%',
	})
}

const pmsClient = new ApolloClient({
	request: operation => {
		const token = getAuthToken()
		if (token) {
			operation.setContext({headers: {Authorization: `Bearer ${token}`}})
		}
	},
	uri: `${getPMSGraphBaseUrl()}`,
	onError: ({graphQLErrors, networkError, operation, forward}) => {
		// const {
		// 	response: {headers},
		// } = operation.getContext()
		const context = operation.getContext()
		const headers = context && context.response && context.response.headers
		headers && gqlErrorHandler(graphQLErrors, networkError, mainStore, headers, operation.variables)
	},
})

const pbmsClient = new ApolloClient({
	request: operation => {
		const token = getAuthToken()
		if (token) {
			operation.setContext({headers: {Authorization: `Bearer ${token}`}})
		}
	},
	uri: `${getPBMSGraphBaseUrl()}`,
	onError: ({graphQLErrors, networkError, operation, forward}) => {
		const {
			response: {headers},
		} = operation.getContext()
		gqlErrorHandler(graphQLErrors, networkError, mainStore, headers)
	},
})

const mpsClient = new ApolloClient({
	request: operation => {
		const token = getAuthToken()
		if (token) {
			operation.setContext({headers: {Authorization: `Bearer ${token}`, timezone: getTimeZone()}})
		}
	},
	uri: `${getMPSGraphBaseUrl()}`,
	onError: ({graphQLErrors, networkError, operation, forward}) => {
		const {
			response: {headers},
		} = operation.getContext()
		gqlErrorHandler(graphQLErrors, networkError, mainStore, headers)
	},
})

const marketPlaceClient = new ApolloClient({
	request: operation => {
		const token = getAuthToken()
		if (token) {
			operation.setContext({headers: {Authorization: `Bearer ${token}`, timezone: getTimeZone()}})
		}
	},
	uri: `${getMarketplaceGraphBaseUrl()}`,
	onError: ({graphQLErrors, networkError, operation, forward}) => {
		const {
			response: {headers},
		} = operation.getContext()
		gqlErrorHandler(graphQLErrors, networkError, mainStore, headers)
	},
})

const mdsClient = new ApolloClient({
	request: operation => {
		const token = getAuthToken()
		if (token) {
			operation.setContext({headers: {Authorization: `Bearer ${token}`}})
		}
	},
	uri: `${getMDSGraphBaseUrl()}`,
	onError: ({graphQLErrors, networkError, operation, forward}) => {
		const {
			response: {headers},
		} = operation.getContext()
		gqlErrorHandler(graphQLErrors, networkError, mainStore, headers)
	},
})

const iamClient = new ApolloClient({
	request: operation => {
		const token = getAuthToken()
		if (token) {
			operation.setContext({headers: {Authorization: `Bearer ${token}`}})
		}
	},
	uri: `${getIAMGraphBaseUrl()}`,
	onError: ({graphQLErrors, networkError, operation, forward}) => {
		const {
			response: {headers},
		} = operation.getContext()
		gqlErrorHandler(graphQLErrors, networkError, mainStore, headers)
	},
})

const configClient = new ApolloClient({
	request: operation => {
		const token = getAuthToken()
		if (token) {
			operation.setContext({headers: {Authorization: `Bearer ${token}`}})
		}
	},
	uri: `${getConfigGraphBaseUrl()}`,
	onError: ({graphQLErrors, networkError, operation, forward}) => {
		const {
			response: {headers},
		} = operation.getContext()
		gqlErrorHandler(graphQLErrors, networkError, mainStore, headers)
	},
})

const locateClient = new ApolloClient({
	request: operation => {
		const token = getAuthToken()
		if (token) {
			operation.setContext({headers: {Authorization: `Bearer ${token}`}})
		}
	},
	uri: `${getLocateGraphBaseUrl()}`,
	onError: ({graphQLErrors, networkError, operation, forward}) => {
		const {
			response: {headers},
		} = operation.getContext()
		gqlErrorHandler(graphQLErrors, networkError, mainStore, headers)
	},
})

export function getMDSClient() {
	return mdsClient
}

export function getPMSClient() {
	return pmsClient
}

export function getPBMSClient() {
	return pbmsClient
}

export function getMPSClient() {
	return mpsClient
}

export function getIAMClient() {
	return iamClient
}

export function getConfigClient() {
	return configClient
}

export function getMarketPlaceClient() {
	return marketPlaceClient
}

export function getLocateClient() {
	return locateClient
}

ReactDOM.render(
	<Provider store={mainStore}>
		<ConnectedRouter history={history}>
			<App />
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root') as HTMLElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
