import {createFetchActions} from '../../../services/createActions'
import {getPMSBaseUrl} from '../../../services/apiService'
import {get} from '../../../services/createAPICall'

export const GET_TRADE_BLOTTER_TYPE = createFetchActions('GET_TRADE_BLOTTER_TYPE')
export const GET_DEFINITION_BY_TRADE_BLOTTER_TYPE = createFetchActions('GET_DEFINITION_BY_TRADE_BLOTTER_TYPE')
export const CLEAR_SELECTION = createFetchActions('CLEAR_SELECTION')

export const getTradeBlotterType = () => dispatch => {
	return dispatch(get(GET_TRADE_BLOTTER_TYPE, `${getPMSBaseUrl()}/v1/tradeBlotterDynamic/tradeBlotterType`))
}

export const getDefinitionsByTradeBlotterType = (tradeBlotterType, rateType?) => dispatch => {
	let url = `${getPMSBaseUrl()}/v1/tradeBlotterDynamic/tradeBlotterType/definitions/${tradeBlotterType}`
	if (rateType) {
		url += `?rateType=${rateType}`
	}
	return dispatch(get(GET_DEFINITION_BY_TRADE_BLOTTER_TYPE, url))
}

export const clearSelection = () => {
	return {
		type: CLEAR_SELECTION,
	}
}
