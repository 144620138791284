import {
	GET_SECURITY_SUGGESTIONS,
	GET_SBL_SEC_AVAILABILITY_DATA,
	GET_LEGAL_ENTITY_SBL_DATA,
	GET_SBL_SEC_LOANS_AND_BORROWS_DATA,
} from './SblSecurityActions'

export interface SblSecurityData {
	loading: boolean
	securitySuggestionsResult: any
	availabilityData: any
	legalEntityData: any[]
	loanAndBorrowData: {}
}

const initialState: SblSecurityData = {
	loading: false,
	securitySuggestionsResult: [],
	availabilityData: [],
	legalEntityData: [],
	loanAndBorrowData: {},
}

export function SblSecurityReducer(state = initialState, action: any) {
	switch (action.type) {
		//----securitySuggestionsResult------

		case GET_SECURITY_SUGGESTIONS.triggered:
			return {...state, loading: true, securitySuggestionsResult: null}
		case GET_SECURITY_SUGGESTIONS.succeeded:
			return {...state, loading: false, securitySuggestionsResult: action.response}
		case GET_SECURITY_SUGGESTIONS.failed:
			return {...state, loading: false, securitySuggestionsResult: []}

		//----availabilityData------

		case GET_SBL_SEC_AVAILABILITY_DATA.triggered:
			return {...state, availabilityData: null}
		case GET_SBL_SEC_AVAILABILITY_DATA.succeeded:
			return {...state, availabilityData: action.response.data}
		case GET_SBL_SEC_AVAILABILITY_DATA.failed:
			return {...state, availabilityData: []}

		//----LegalEntityData------

		case GET_LEGAL_ENTITY_SBL_DATA.triggered:
			return {...state, legalEntityData: null}
		case GET_LEGAL_ENTITY_SBL_DATA.succeeded:
			return {...state, legalEntityData: action.response.data}
		case GET_LEGAL_ENTITY_SBL_DATA.failed:
			return {...state, legalEntityData: []}

		//----LoansAndBorrowsData------

		case GET_SBL_SEC_LOANS_AND_BORROWS_DATA.triggered:
			return {...state, loanAndBorrowData: null}
		case GET_SBL_SEC_LOANS_AND_BORROWS_DATA.succeeded:
			return {...state, loanAndBorrowData: action.response.data}
		case GET_SBL_SEC_LOANS_AND_BORROWS_DATA.failed:
			return {...state, loanAndBorrowData: {}}
	}
	return state
}
