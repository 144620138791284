import {getLocateClient} from '../'
import {createFetchActions} from '../services/createActions'
import {getConfigGraphBaseUrl, getIAMBaseUrl, getMDSBaseUrl, getPMSGraphBaseUrl} from '../services/apiService'
import {
	getPortfolioAllEntriesQuery,
	getRestrictedEntriesQuery,
	getPortfolioSelectedEntriesQuery,
	getExecutionNotificationsQuery,
	getLocateClientNotificationsQuery,
	getLocateAuthoriserNotificationsQuery,
	getAllFundQuery,
	getAllPortfolioQuery,
} from './appQueries'
import {get, graphQlWithAxios, put} from '../services/createAPICall'

export const SET_NAVBAR_TYPE = 'SET_NAVBAR_TYPE'
export const LOADER_START = 'LOADER_START'
export const LOADER_STOP = 'LOADER_STOP'
export const SET_DARK_THEME = 'SET_DARK_THEME'
export const GET_PORTFOLIO_ALL_ENTRIES = createFetchActions('GET_ALL_ENTRIES')
export const GET_PORTFOLIO_SELECTED_ENTRIES = createFetchActions('GET_PORTFOLIO_SELECTED_ENTRIES')
export const GET_RESTRICTED_ENTRIES = createFetchActions('GET_RESTRICTED_ENTRIES')
export const SET_SUGGESTIONS_THEME = 'SET_SUGGESTIONS_THEME'
export const GET_EXECUTION_NOTIFICATIONS = createFetchActions('GET_EXECUTION_NOTIFICATIONS')
export const GET_LOCATE_NOTIFICATIONS = createFetchActions('GET_LOCATE_NOTIFICATIONS')
export const GET_LOCATE_CLIENT_NOTIFICATIONS = createFetchActions('GET_LOCATE_CLIENT_NOTIFICATIONS')
export const AUTH_LOGOUT = createFetchActions('AUTH_LOGOUT')
export const GET_ALL_FUND = createFetchActions('GET_ALL_FUND')
export const GET_ALL_PORTFOLIO = createFetchActions('GET_ALL_PORTFOLIO')
export const CLEAR_ALL_FUND = createFetchActions('CLEAR_ALL_FUND')
export const CLEAR_ALL_PORTFOLIO = createFetchActions('CLEAR_ALL_PORTFOLIO')
export const LOGIN_LOGS = createFetchActions('LOGIN_LOGS')

export function setNavbarType(isWide: any) {
	return {
		type: SET_NAVBAR_TYPE,
		data: isWide,
	}
}

export function startLoader(message: any) {
	return {
		type: LOADER_START,
		data: message,
	}
}

export function stopLoader() {
	return {
		type: LOADER_STOP,
		data: null,
	}
}

export const getPortfolioAllEntries = () => {
	const query = getPortfolioAllEntriesQuery()
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_PORTFOLIO_ALL_ENTRIES, baseURL, query, data => {
			const allEntriesData = data && data.data && data.data.data
			const allEntries =
				allEntriesData && allEntriesData.portfolio && allEntriesData.portfolio.all
					? allEntriesData.portfolio.all.entries
					: []
			const pendingOrders =
				allEntriesData && allEntriesData.portfolio && allEntriesData.portfolio.pendingOrders
					? allEntriesData.portfolio.pendingOrders.entries
					: []
			const entries = allEntries.concat(pendingOrders)
			dispatch({
				type: GET_PORTFOLIO_ALL_ENTRIES && GET_PORTFOLIO_ALL_ENTRIES.succeeded,
				response: entries,
			})
		})
	}
}

export const getPortfolioSelectedEntries = (selectedEntriesId: any[]) => {
	const query = getPortfolioSelectedEntriesQuery(selectedEntriesId)
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_PORTFOLIO_SELECTED_ENTRIES, baseURL, query, data => {
			const portfolioEntries = data && data.data && data.data.data && data.data.data.portfolioEntries
			const newEntries = portfolioEntries ? portfolioEntries.entries : []
			dispatch({
				type: GET_PORTFOLIO_SELECTED_ENTRIES && GET_PORTFOLIO_SELECTED_ENTRIES.succeeded,
				response: newEntries,
			})
		})
	}
}

export const getRestrictedEntries = () => {
	const query = getRestrictedEntriesQuery()
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_RESTRICTED_ENTRIES, baseURL, query, (response: any) => {
			const entries =
				response && response.data && response.data.data && response.data.data.restrictedSecurityDocumentEntries
			dispatch({
				type: GET_RESTRICTED_ENTRIES && GET_RESTRICTED_ENTRIES.succeeded,
				response: entries,
			})
		})
	}
}

export const setDarkTheme = (isDarkTheme: boolean) => {
	return {
		type: SET_DARK_THEME,
		response: isDarkTheme,
	}
}

export const setSuggestions = (suggestions: boolean) => {
	return {
		type: SET_SUGGESTIONS_THEME,
		response: suggestions,
	}
}

export const getExecutionNotifications = () => {
	const query = getExecutionNotificationsQuery()
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_EXECUTION_NOTIFICATIONS, baseURL, query, (response: any) => {
			const notifications = response && response.data && response.data.data && response.data.data.executionSummary
			const data = notifications ? notifications : {}
			dispatch({
				type: GET_EXECUTION_NOTIFICATIONS.succeeded,
				response: data,
			})
		})
	}
}

export const getLocateNotifications = () => {
	return function (dispatch) {
		dispatch({
			type: GET_LOCATE_NOTIFICATIONS.triggered,
		})
		getLocateClient()
			.query({
				query: getLocateAuthoriserNotificationsQuery(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_LOCATE_NOTIFICATIONS.succeeded,
					response,
				})
			})
	}
}

export const getLocateClientNotifications = () => {
	return function (dispatch) {
		dispatch({
			type: GET_LOCATE_CLIENT_NOTIFICATIONS.triggered,
		})
		getLocateClient()
			.query({
				query: getLocateClientNotificationsQuery(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_LOCATE_CLIENT_NOTIFICATIONS.succeeded,
					response,
				})
			})
	}
}

export const authLogout = () => async (dispatch: any) => {
	await dispatch(put(LOGIN_LOGS, `${getMDSBaseUrl()}/v1/mds/user/logout`))
	return dispatch(get(AUTH_LOGOUT, `${getIAMBaseUrl()}/auth/logout`))
}

export const getFund = () => {
	const query = getAllFundQuery()
	const baseURL = getConfigGraphBaseUrl()
	return function (dispatch) {
		dispatch({type: GET_ALL_FUND.triggered})
		graphQlWithAxios(dispatch, GET_ALL_FUND, baseURL, query, (response: any) => {
			const entries = response && response.data
			dispatch({
				type: GET_ALL_FUND.succeeded,
				response: entries,
			})
		})
	}
}

export const clearFundsData = () => {
	return {
		type: CLEAR_ALL_FUND,
	}
}

export const getPortfolio = () => {
	const query = getAllPortfolioQuery()
	const baseURL = getConfigGraphBaseUrl()
	return function (dispatch) {
		dispatch({type: GET_ALL_PORTFOLIO.triggered})
		graphQlWithAxios(dispatch, GET_ALL_PORTFOLIO, baseURL, query, (response: any) => {
			const entries = response && response.data
			dispatch({
				type: GET_ALL_PORTFOLIO.succeeded,
				response: entries,
			})
		})
	}
}

export const clearPortfolios = () => {
	return {
		type: CLEAR_ALL_PORTFOLIO,
	}
}
