import React, {useState, useRef, useEffect} from 'react'
import {Form} from 'react-bootstrap'
import {EditIcon} from '../../styles/svg/svgIcons'
import {restrictSpecialCharacterFn} from '../../utils/textFieldUtil'

const EditableNameField = props => {
	const {dataItem, handleEdit} = props
	const [editing, setEditing] = useState(false)
	const [name, setName] = useState(dataItem.name)
	const inputField = useRef(null)

	useEffect(() => {
		if (editing) {
			inputField.current.focus()
		}
	}, [editing])

	const handleChange = (event: any) => {
		setName(event.target.value)
	}

	const handleBlur = (event: any) => {
		setEditing(false)
		handleEdit(name, dataItem.menuId)
	}

	return (
		<>
			{editing ? (
				<Form.Control
					ref={inputField}
					type='text'
					name='itemName'
					value={name}
					className='form-control mr-2 form-control-sm p-2'
					onChange={handleChange}
					onBlur={handleBlur}
					onKeyDown={restrictSpecialCharacterFn}
				/>
			) : (
				<span className='pr-2 lh-0'>{dataItem.name}</span>
			)}
			<span
				className='lh-0 cursor-pointer'
				onClick={() => {
					setEditing(!editing)
				}}
			>
				<EditIcon />
			</span>
		</>
	)
}

export default EditableNameField
