import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {MainState} from '../store/mainReducer'
import {
	setNavbarType,
	startLoader,
	stopLoader,
	getPortfolioAllEntries,
	getRestrictedEntries,
	setDarkTheme,
	setSuggestions,
	getExecutionNotifications,
	getLocateNotifications,
	getLocateClientNotifications,
	authLogout,
} from './appActions'
import {getActiveMenu} from '../features/customiseMenu/customiseMenuActions'
import App from './appComponent'
import {getPortfolioStatus} from '../features/portfolioStatus/portfolioStatusAction'
import {getHolidays} from '../pages/PeerToPeer/OrderEntryModal/OrderEntryModalAction'

const mapStateToProps = (state: MainState) => {
	return {
		isLoading: state.app.isLoading,
		message: state.app.message,
		windowHeight: state.app.windowHeight,
		windowWidth: state.app.windowWidth,
		wideNavbar: state.app.wideNavbar,
		errorMessage: state.errorHandler.errorMessage,
		portfolioStatus: state.portfolioStatus,
		holidays: state.orderEntryModal.holidays,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		authLogout: () => {
			return dispatch(authLogout())
		},
		setNavbarType: (isWide: boolean) => {
			return dispatch(setNavbarType(isWide))
		},
		startLoader: (message: string) => {
			return dispatch(startLoader(message))
		},
		stopLoader: () => {
			return dispatch(stopLoader())
		},
		getPortfolioAllEntries: () => {
			return dispatch(getPortfolioAllEntries())
		},
		getRestrictedEntries: () => {
			return dispatch(getRestrictedEntries())
		},
		getPortfolioStatus: () => {
			return dispatch(getPortfolioStatus())
		},
		setDarkTheme: (darkTheme: boolean) => {
			return dispatch(setDarkTheme(darkTheme))
		},
		setSuggestions: (suggestion: boolean) => {
			return dispatch(setSuggestions(suggestion))
		},
		getExecutionNotifications: () => {
			return dispatch(getExecutionNotifications())
		},
		getActiveMenu: () => {
			return dispatch(getActiveMenu())
		},
		getLocateNotifications: () => {
			return dispatch(getLocateNotifications())
		},
		getLocateClientNotifications: () => {
			return dispatch(getLocateClientNotifications())
		},
		getHolidays: () => {
			return dispatch(getHolidays())
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
