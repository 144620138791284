import {globalColumnDefinition} from '../../common/AgGrid/AgGridColumn'
import {noCommaTwoDecimal} from '../../common/AgGrid/AgGridHelper'
import {ArrayUtil, ObjectUtil} from 'helper-util'
import {extractData} from '../../features/DynamicForm/DynamicFormHelper'

export const getCommonColumn = (isFosColumn, tradeTicketButton) => {
	const columns = [
		globalColumnDefinition.securityId,
		globalColumnDefinition.securityDescription,
		globalColumnDefinition.fileName,
		Object.assign({}, globalColumnDefinition.strategy, {headerName: 'Trade Type'}),
		globalColumnDefinition.buySellInd,
		globalColumnDefinition.legalEntityExternalId,
		globalColumnDefinition.counterPartyName,
		globalColumnDefinition.book,
		Object.assign({}, globalColumnDefinition.allInRate, isFosColumn ? noCommaTwoDecimal : {}),
		globalColumnDefinition.benchmark,
		globalColumnDefinition.spread,
		Object.assign({}, globalColumnDefinition.allInPrice, isFosColumn ? noCommaTwoDecimal : {}),
		globalColumnDefinition.collateralType,
		isFosColumn
			? Object.assign({}, globalColumnDefinition.quantity, {headerName: 'Quantity/Principal'})
			: globalColumnDefinition.quantity,
		globalColumnDefinition.price,
		tradeTicketButton ? globalColumnDefinition.haircut : globalColumnDefinition.margin,
		globalColumnDefinition.notional,
		globalColumnDefinition.currency,
		globalColumnDefinition.maturityType,
		globalColumnDefinition.tradeDate,
		globalColumnDefinition.maturityDate,
		globalColumnDefinition.endDate,
		globalColumnDefinition.settlementDate,
		globalColumnDefinition.tradeComments,
		Object.assign({}, globalColumnDefinition.tradeId, {field: 'id'}),
		isFosColumn
			? Object.assign({}, globalColumnDefinition.startCash, {headerName: 'Start Cash/Contract Value'})
			: globalColumnDefinition.startCash,
		globalColumnDefinition.endCash,
		Object.assign({}, globalColumnDefinition.puttable, {
			cellRenderer: params => {
				return params.value === true ? 'Y' : 'N'
			},
		}),
		globalColumnDefinition.puttableDate,
		Object.assign({}, globalColumnDefinition.callable, {
			cellRenderer: params => {
				return params.value === true ? 'Y' : 'N'
			},
		}),
		globalColumnDefinition.callableDate,
		globalColumnDefinition.proceeds,
		globalColumnDefinition.par,
		Object.assign({}, globalColumnDefinition.discountRate, isFosColumn ? noCommaTwoDecimal : {}),
		globalColumnDefinition.discountAmount,
		Object.assign({}, globalColumnDefinition.impliedAllInRate, isFosColumn ? noCommaTwoDecimal : {}),
	]
	if (isFosColumn) {
		columns.push(
			globalColumnDefinition.createdBy,
			Object.assign({}, globalColumnDefinition.createdDate, {headerName: 'Time Stamp'}),
			globalColumnDefinition.tenor
		)
	}
	return columns
}

export const getTradeColumns = (commonColumn, handleDeleteTrade) => {
	return [
		Object.assign({}, globalColumnDefinition.deleteField, {
			onCellClicked: params => {
				handleDeleteTrade(params.data.id)
			},
		}),
		...commonColumn,
	]
}

export const updateSpread = allTrades => {
	const updatedAllTrades = allTrades
		? allTrades.map(trade => {
				trade.delete = true
				trade.spread *= 100
				trade.margin = trade.haircut
				return trade
		  })
		: allTrades

	return updatedAllTrades
}

export const getDynamicFormData = data => {
	const quickFieldSection = []
	!ObjectUtil.isEmpty(data) &&
		data.templates.forEach(template => {
			const quickFieldTemplate = []
			template.fields.forEach(field => {
				const detail = {
					id: template.id,
					type: field.displayType || 'quick',
					field,
				}
				quickFieldTemplate.push(detail)
			})
			!ArrayUtil.isEmpty(quickFieldTemplate) && quickFieldSection.push(quickFieldTemplate)
		})
	const {formulaBasedData, defaultTradeData} = extractData(data)

	return {formulaBasedData, defaultTradeData, quickFieldSection}
}
