import {GET_USER_GROUPS, EDIT_USER_GROUPS, SAVE_USER_GROUPS} from './userGroupActions'
import {GET_SELECTED_GROUP, CLEAR_SELECTED_GROUP_DETAILS} from './userGroupActions'
import {CLEAR_USER_GROUPS, GET_MODULES, GET_STRATEGIES} from './userGroupActions'
import {modifyData} from './userGroupHelper'
import {UserGroup, Strategies, Modules} from './userGroupModel'

export interface UserGroupData {
	loading: boolean
	data: UserGroup[]
	modules: Modules[]
	strategies: Strategies[]
	selectedGroupDetails: UserGroup
}

const initialState: UserGroupData = {
	loading: false,
	data: null,
	modules: [],
	strategies: [],
	selectedGroupDetails: null,
}

export function userGroupReducer(state = initialState, action: any) {
	let data: UserGroup[] = []
	let modules: Modules[] = []
	let strategies: Strategies[] = []
	switch (action.type) {
		case GET_USER_GROUPS.triggered:
			return {...state, loading: true}
		case GET_USER_GROUPS.succeeded:
			data = action.response.data || []
			return {...state, loading: false, data: modifyData(data) || []}
		case GET_USER_GROUPS.failed:
			return {...state, loading: false}

		case GET_SELECTED_GROUP.triggered:
			return {...state, loading: true}
		case GET_SELECTED_GROUP.succeeded:
			const selectedGroupDetails: UserGroup = action.response.data
			return {...state, loading: false, selectedGroupDetails}
		case GET_SELECTED_GROUP.failed:
			return {...state, loading: false, selectedGroupDetails: null}

		case CLEAR_SELECTED_GROUP_DETAILS:
			return {...state, selectedGroupDetails: null}

		case GET_STRATEGIES.triggered:
			return {...state, loading: true}
		case GET_STRATEGIES.succeeded:
			strategies = (action.response.data && action.response.data.allStrategies) || []
			return {...state, loading: false, strategies}
		case GET_STRATEGIES.failed:
			return {...state, loading: false}

		case GET_MODULES.triggered:
			return {...state, loading: true}
		case GET_MODULES.succeeded:
			modules = (action.response.data && action.response.data.allModules) || []
			return {...state, loading: false, modules}
		case GET_MODULES.failed:
			return {...state, loading: false}

		case SAVE_USER_GROUPS.triggered:
			return {...state, loading: true}
		case SAVE_USER_GROUPS.succeeded:
		case SAVE_USER_GROUPS.failed:
			return {...state, loading: false}

		case EDIT_USER_GROUPS.triggered:
			return {...state, loading: true}
		case EDIT_USER_GROUPS.succeeded:
		case EDIT_USER_GROUPS.failed:
			return {...state, loading: false}

		case CLEAR_USER_GROUPS:
			return {...state, data: null}
	}

	return state
}
