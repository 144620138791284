import {GET_DEPOS, GET_DEPOS_TYPES, SAVE_DEPOS, EDIT_DEPOS} from './deposActions'
import {Depos} from './deposModel'

export interface DeposData {
	loading: boolean
	data: Depos[]
	depoTypes: string[]
}

const initialState: DeposData = {
	loading: false,
	data: null,
	depoTypes: [],
}

export function deposReducer(state = initialState, action: any) {
	let data: Depos[] = []
	switch (action.type) {
		case GET_DEPOS.triggered:
			return {...state, loading: true}
		case GET_DEPOS.succeeded:
			data = (action.response.data && action.response.data.allDepos) || []
			return {...state, loading: false, data}
		case GET_DEPOS.failed:
			return {...state, loading: false}

		case GET_DEPOS_TYPES.triggered:
			return {...state, loading: true}
		case GET_DEPOS_TYPES.succeeded:
			return {...state, loading: false, depoTypes: action.response.data || []}
		case GET_DEPOS_TYPES.failed:
			return {...state, loading: false}

		case SAVE_DEPOS.triggered:
			return {...state, loading: true}
		case SAVE_DEPOS.succeeded:
		case SAVE_DEPOS.failed:
			return {...state, loading: false}

		case EDIT_DEPOS.triggered:
			return {...state, loading: true}
		case EDIT_DEPOS.succeeded:
		case EDIT_DEPOS.failed:
			return {...state, loading: false}
	}

	return state
}
