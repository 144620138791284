import React, {FC, HTMLAttributes, ReactNode} from 'react'
import {Button, ButtonProps, Modal, ModalProps} from 'react-bootstrap'
import {CloseIcon} from '../styles/svg/svgIcons'

type ActionButtonProps = HTMLAttributes<HTMLButtonElement> & ButtonProps & {label: string; icon?: any}

export type FsModalProps = ModalProps & {
	title?: string
	titleClass?: string
	closable?: boolean
	actions?: {
		leftAlign?: ActionButtonProps[]
		rightAlign?: ActionButtonProps[]
		centerAlign?: ActionButtonProps[]
	}
	headerActions?: ReactNode
	showPreview?: boolean
	previewContent?: ReactNode
	inputClassName?: string
}

export const FsModal: FC<FsModalProps> = ({
	title,
	titleClass,
	closable,
	actions,
	headerActions,
	showPreview = false,
	previewContent,
	children,
	inputClassName,
	backdrop,
	...modalProps
}) => {
	const className = `${inputClassName} ${modalProps.scrollable && 'modal-scrollable'}`

	return (
		<Modal
			enforceFocus={false}
			{...modalProps}
			backdrop={backdrop || 'static'}
			className={className}
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			{(title || closable) && (
				<Modal.Header className='modal-header'>
					<div>
						<span className={`f-18 font-weight-600 text-grey-10 ${titleClass}`}>{title}</span>
						<span>{headerActions}</span>
					</div>
					{closable && (
						<div className='text-grey-3 cursor-pointer' onClick={modalProps.onHide}>
							<CloseIcon />
						</div>
					)}
				</Modal.Header>
			)}

			<Modal.Body className='modal-body p-4'>{children}</Modal.Body>

			{actions && (
				<Modal.Footer className='d-flex flex-column'>
					{showPreview && previewContent}
					<div className='d-flex justify-content-between w-100'>
						<div>
							{actions.leftAlign &&
								actions.leftAlign.map(({label, icon, ...props}, i, all) => (
									<Button key={`left-btn-${i}`} className={i + 1 !== all.length && 'mr-2'} {...props}>
										{icon}
										{label}
									</Button>
								))}
						</div>
						<div>
							{actions.centerAlign &&
								actions.centerAlign.map(({label, icon, ...props}, i, all) => (
									<Button key={`center-btn-${i}`} className={i + 1 !== all.length && 'mr-2'} {...props}>
										{icon}
										{label}
									</Button>
								))}
						</div>
						<div>
							{actions.rightAlign &&
								actions.rightAlign.map(({label, icon, ...props}, i, all) => (
									<Button key={`right-btn-${i}`} className={i + 1 !== all.length && 'mr-2'} {...props}>
										{icon}
										{label}
									</Button>
								))}
						</div>
					</div>
				</Modal.Footer>
			)}
		</Modal>
	)
}
