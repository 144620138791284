import {
	CLEAR_SELECTION,
	GET_DEFINITION_BY_TRADE_BLOTTER_TYPE,
	GET_TRADE_BLOTTER_TYPE,
} from './NewTradeModalDynamicAction'
import {getDynamicFormData} from '../TradeBlotterDynamicHelper'

export interface NewTradeDynamicModal {
	tradeBlotterTypeList: any[]
	templateDefinitions: any
	quickFieldSection: any
	formulaBasedData: any
	defaultTradeData: any
}

const initialState: NewTradeDynamicModal = {
	tradeBlotterTypeList: [],
	templateDefinitions: [],
	quickFieldSection: [],
	formulaBasedData: [],
	defaultTradeData: {},
}

export function newTradeModalDynamicReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_TRADE_BLOTTER_TYPE.triggered:
			return {...state, tradeBlotterTypeList: null}
		case GET_TRADE_BLOTTER_TYPE.succeeded:
			return {...state, tradeBlotterTypeList: action.response.data}
		case GET_TRADE_BLOTTER_TYPE.failed:
			return {...state, tradeBlotterTypeList: []}

		case GET_DEFINITION_BY_TRADE_BLOTTER_TYPE.triggered:
			return {...state, templateDefinitions: null}
		case GET_DEFINITION_BY_TRADE_BLOTTER_TYPE.succeeded:
			const {formulaBasedData, defaultTradeData, quickFieldSection} = getDynamicFormData(action.response.data)
			return {
				...state,
				templateDefinitions: action.response.data,
				quickFieldSection,
				formulaBasedData,
				defaultTradeData,
			}
		case GET_DEFINITION_BY_TRADE_BLOTTER_TYPE.failed:
			return {...state, templateDefinitions: null}

		case CLEAR_SELECTION:
			return {...state, quickFieldSection: []}
	}

	return state
}
