import {Client} from '@stomp/stompjs'
import React from 'react'
import SockJS from 'sockjs-client'
import {Toast} from 'react-bootstrap'
import {ArrayUtil, FormatUtil} from 'helper-util'
import {getMPSWSBaseUrl, getPMSWSBaseUrl, getLocateWSBaseUrl} from '../../services/apiService'
import {getAlpWSBaseUrl, getMarginAnalysisWSBaseUrl} from '../../services/apiService'
import {getAuthToken, getUserGroupId, getCounterpartyId, getUserId, getModuleGroupMap} from '../../services/authService'
import {Props, State} from './webSocketModel'
import {
	pmsUserQueueNotificationEmitter,
	mpsTenantTopicOrderNotificationEmitter,
	alpTopicUserEmitter,
} from './webSocketEmitter'
import {auctionUpdateTopicTriggerEmitter, auctionUpdateTopicUnsubscribeEmitter} from './webSocketEmitter'
import {auctionUpdateTopicGroupEmitter} from './webSocketEmitter'
import {auctionTopicTenantGroupEmitter} from './webSocketEmitter'
import {auctionTopicBorrowerGroupEmitter} from './webSocketEmitter'
import {alpActivityCreatedOnlyLocateTopicGroupEmitter} from './webSocketEmitter'
import {alpActivityCreatedTopicTenantEmitter, marginAnalysisTopicUserEmitter} from './webSocketEmitter'
import {auctionTopicGroupUnsubscribeEmitter, auctionTopicGroupTriggerEmitter} from './webSocketEmitter'
import {auctionTopicGroupEmitter} from './webSocketEmitter'
// import {alpBatchTopicGroupTriggerEmitter, alpBatchTopicGroupEmitter} from './webSocketEmitter'
// import {alpBatchTopicGroupUnsubscribeEmitter} from './webSocketEmitter'
import {alpActivityCreatedTopicGroupEmitter} from './webSocketEmitter'
import {pmsTopicGroupEmitter, pmsActivityCreatedTopicGroupEmitter} from './webSocketEmitter'
import {pmsRestrictedSecurityDocumentUploadedEmitter} from './webSocketEmitter'
import {pmsAbcmRerunEmitter, pmsConfigurationUpdateEmitter} from './webSocketEmitter'
import {locateActivityCreatedTopicGroupEmitter} from './webSocketEmitter'
import {initializeGlobalSubscriber, unsubscribe} from './webSocketSubscriber'
import {allowedWss} from './webSocketConstant'
import {Config} from '../../constants/clientConfig'

export default class WebSocketNotification extends React.Component<Props, State> {
	pmsClient: any
	mpsClient: any
	locateClient: any
	alpClient: any
	marginAnalysisClient: any
	enableErrorLog: boolean

	alpBatchTopicGroupTriggerSubscriber: any
	auctionTopicGroupTriggerSubscriber: any
	auctionUpdateTopicTriggerSubscriber: any

	constructor(props) {
		super(props)
		this.state = {
			isConnectionLost: false,
		}
		this.enableErrorLog = false
	}

	componentDidMount() {
		const {MPS, PMS, LOCATE} = allowedWss[Config.activeConfig.id || 'default']
		MPS && this.connectMPSClient()
		PMS && this.connectPMSClient()
		LOCATE && this.connectLocateClient()
		// ALP && this.connectAlpClient()
		// MNA && this.connectMarginAnalysisClient()

		const {pendingAbcmStatus, getPortfolioStatus, setNotificationData, getPortfolioAllEntries} = this.props
		const {getPortfolioSelectedEntries, getRestrictedEntries, resetDashboardData, getTradingQueueData} = this.props
		const {resetAllTrades, getBasketDetails, setAbcmRerunTiming, reloadFundCashFlowData, getTargetedInventory} =
			this.props
		const {getExecutionNotifications, getLocateNotifications, getLocateClientNotifications} = this.props
		const {updateRowDataAuthorizer, updateRowDataRequester, getPortfolioDocsIncluded, getPortfolioDocsAll} = this.props
		const {getCostOfFunds, getCashPositionAndFlow, fetchLocateAuthorizerDetails, fetchLocateRequesterDetails} =
			this.props
		const {fetchAllSecurities, getKPISummary} = this.props

		const action = {
			getPortfolioDocsIncluded,
			getPortfolioDocsAll,
			pendingAbcmStatus,
			getPortfolioStatus,
			getPortfolioAllEntries,
			getPortfolioSelectedEntries: ids => getPortfolioSelectedEntries(ids),
			resetDashboardData,
			resetAllTrades,
			getRestrictedEntries,
			getBasketDetails,
			setAbcmRerunTiming,
			getTradingQueueData,
			reloadFundCashFlowData,
			getExecutionNotifications,
			getCostOfFunds,
			getCashPositionAndFlow,
			fetchLocateAuthorizerDetails,
			fetchLocateRequesterDetails,
			getLocateNotifications,
			getLocateClientNotifications,
			getTargetedInventory,
			updateRowDataAuthorizer,
			updateRowDataRequester,
			getKPISummary,
			fetchAllSecurities,
		}
		initializeGlobalSubscriber(action, setNotificationData)
	}

	componentWillUnmount() {
		unsubscribe()
	}

	connectMPSClient = () => {
		this.mpsClient = new Client({
			connectHeaders: {
				Authorization: getAuthToken(),
			},
			debug: function (str) {},
		})

		this.mpsClient.onConnect = cb => {
			this.enableErrorLog && console.log(`MPS connected: ${new Date()}`)

			const tenantTopicOrderUrl = `/topic/tenant-${getCounterpartyId()}`
			this.mpsClient.subscribe(tenantTopicOrderUrl, notification => {
				mpsTenantTopicOrderNotificationEmitter.next(notification)
			})
		}

		this.mpsClient.onDisconnect = cb => {
			this.enableErrorLog && console.log(`MPS onDisconnect: ${new Date()} \n`, cb)
		}

		this.mpsClient.onStompError = cb => {
			this.enableErrorLog && console.log(`MPS onStompError: ${new Date()} \n`, cb)
		}

		this.mpsClient.onWebSocketError = cb => {
			this.enableErrorLog && console.log(`MPS onWebSocketError: ${new Date()} \n`, cb)
		}

		this.mpsClient.onWebSocketClose = cb => {
			this.enableErrorLog && console.log(`MPS onWebSocketClose: ${new Date()} \n`, cb)
		}

		this.mpsClient.webSocketFactory = () => {
			return new SockJS(`${getMPSWSBaseUrl()}?tId=${getCounterpartyId()}`)
		}

		this.mpsClient.activate()
	}

	connectPMSClient = () => {
		this.pmsClient = new Client({
			connectHeaders: {
				Authorization: getAuthToken(),
			},
			debug: function (str) {},
		})

		this.pmsClient.onConnect = cb => {
			this.enableErrorLog && console.log(`PMS connected: ${new Date()}`)

			const moduleGroupId = getModuleGroupMap()
			if (!ArrayUtil.isEmpty(moduleGroupId['PMS'])) {
				moduleGroupId['PMS'].forEach(groupId => {
					this.pmsNotifier(groupId)
				})
			} else {
				this.pmsNotifier(getUserGroupId())
			}

			const topicUserUrl = `/topic/user-${getUserId()}`
			this.pmsClient.subscribe(topicUserUrl, notification => {
				pmsUserQueueNotificationEmitter.next(notification)
			})
		}

		this.pmsClient.onDisconnect = cb => {
			this.enableErrorLog && console.log(`PMS onDisconnect: ${new Date()}\n`, cb)
		}

		this.pmsClient.onStompError = cb => {
			this.enableErrorLog && console.log(`PMS onStompError: ${new Date()}\n`, cb)
		}

		this.pmsClient.onWebSocketError = cb => {
			this.enableErrorLog && console.log(`PMS onWebSocketError: ${new Date()}\n`, cb)
		}

		this.pmsClient.onWebSocketClose = cb => {
			this.enableErrorLog && console.log(`PMS onWebSocketClose: ${new Date()}\n`, cb)
		}

		this.pmsClient.webSocketFactory = () => {
			return new SockJS(`${getPMSWSBaseUrl()}?tId=${getCounterpartyId()}`)
		}

		this.pmsClient.activate()
	}

	connectLocateClient = () => {
		this.locateClient = new Client({
			connectHeaders: {
				Authorization: getAuthToken(),
			},
			debug: function (str) {},
		})

		this.locateClient.onConnect = cb => {
			this.enableErrorLog && console.log(`LOCATE connected: ${new Date()}`)

			const moduleGroupId = getModuleGroupMap()
			if (!ArrayUtil.isEmpty(moduleGroupId['LOCATE'])) {
				moduleGroupId['LOCATE'].forEach(groupId => {
					this.locateNotifier(groupId)
				})
			} else {
				this.locateNotifier(getUserGroupId())
			}
		}

		this.locateClient.onDisconnect = cb => {
			this.enableErrorLog && console.log(`LOCATE onDisconnect: ${new Date()}\n`, cb)
		}

		this.locateClient.onStompError = cb => {
			this.enableErrorLog && console.log(`LOCATE onStompError: ${new Date()}\n`, cb)
		}

		this.locateClient.onWebSocketError = cb => {
			this.enableErrorLog && console.log(`LOCATE onWebSocketError: ${new Date()}\n`, cb)
		}

		this.locateClient.onWebSocketClose = cb => {
			this.enableErrorLog && console.log(`LOCATE onWebSocketClose: ${new Date()}\n`, cb)
		}

		this.locateClient.webSocketFactory = () => {
			return new SockJS(`${getLocateWSBaseUrl()}?tId=${getCounterpartyId()}`)
		}

		this.locateClient.activate()
	}

	connectAlpClient = () => {
		this.alpClient = new Client({
			connectHeaders: {
				Authorization: getAuthToken(),
			},
			debug: function (str) {},
		})

		this.alpClient.onConnect = cb => {
			this.enableErrorLog && console.log(`ALP connected: ${new Date()}`)

			const moduleGroupId = getModuleGroupMap()
			if (!ArrayUtil.isEmpty(moduleGroupId['ALP'])) {
				moduleGroupId['ALP'].forEach(groupId => {
					this.alpNotifier(groupId)
				})
			} else {
				this.alpNotifier(getUserGroupId())
				this.auctionNotifier()
			}

			const tenantTopicOrderUrl = `/topic/tenant-${getCounterpartyId()}`
			this.alpClient.subscribe(tenantTopicOrderUrl, notification => {
				alpActivityCreatedTopicTenantEmitter.next(notification)
			})
		}

		this.alpClient.onDisconnect = cb => {
			this.enableErrorLog && console.log(`ALP onDisconnect: ${new Date()}\n`, cb)
		}

		this.alpClient.onStompError = cb => {
			this.enableErrorLog && console.log(`ALP onStompError: ${new Date()}\n`, cb)
		}

		this.alpClient.onWebSocketError = cb => {
			this.enableErrorLog && console.log(`ALP onWebSocketError: ${new Date()}\n`, cb)
		}

		this.alpClient.onWebSocketClose = cb => {
			this.enableErrorLog && console.log(`ALP onWebSocketClose: ${new Date()}\n`, cb)
		}

		this.alpClient.webSocketFactory = () => {
			return new SockJS(`${getAlpWSBaseUrl()}?tId=${getCounterpartyId()}`)
		}

		this.alpClient.activate()
	}

	connectMarginAnalysisClient = () => {
		this.marginAnalysisClient = new Client({
			connectHeaders: {
				Authorization: getAuthToken(),
			},
			debug: function (str) {},
		})

		this.marginAnalysisClient.onConnect = cb => {
			this.enableErrorLog && console.log(`Margin Analysis connected: ${new Date()}`)

			const userTopicMarginAnalysisUrl = `/topic/user-${getUserId()}`
			this.marginAnalysisClient.subscribe(userTopicMarginAnalysisUrl, notification => {
				marginAnalysisTopicUserEmitter.next(notification)
			})
		}

		this.marginAnalysisClient.onDisconnect = cb => {
			this.enableErrorLog && console.log(`Margin Analysis onDisconnect: ${new Date()}\n`, cb)
		}

		this.marginAnalysisClient.onStompError = cb => {
			this.enableErrorLog && console.log(`Margin Analysis onStompError: ${new Date()}\n`, cb)
		}

		this.marginAnalysisClient.onWebSocketError = cb => {
			this.enableErrorLog && console.log(`Margin Analysis onWebSocketError: ${new Date()}\n`, cb)
		}

		this.marginAnalysisClient.onWebSocketClose = cb => {
			this.enableErrorLog && console.log(`Margin Analysis onWebSocketClose: ${new Date()}\n`, cb)
		}

		this.marginAnalysisClient.webSocketFactory = () => {
			return new SockJS(`${getMarginAnalysisWSBaseUrl()}?tId=${getCounterpartyId()}`)
		}

		this.marginAnalysisClient.activate()
	}

	pmsNotifier = groupId => {
		const topicGroupUrl = `/topic/group-${groupId}`
		this.pmsClient.subscribe(topicGroupUrl, notification => {
			const body = JSON.parse(notification.body)

			if (FormatUtil.text.toLowerCase(body.type) === 'trading_queue') {
				pmsActivityCreatedTopicGroupEmitter.next(body)
			}

			if (FormatUtil.text.toLowerCase(body.type) === 'incoming_activity_created') {
				pmsActivityCreatedTopicGroupEmitter.next(body)
			}
			if (FormatUtil.text.toLowerCase(body.type) === 'outgoing_activity_created') {
				pmsActivityCreatedTopicGroupEmitter.next(body)
			}

			if (FormatUtil.text.toLowerCase(body.type) === 'activity_action') {
				pmsActivityCreatedTopicGroupEmitter.next(body)
			}

			if (FormatUtil.text.toLowerCase(body.type) === 'activity_created') {
				pmsActivityCreatedTopicGroupEmitter.next(body)
			}

			if (FormatUtil.text.toLowerCase(body.type) === 'email') {
				pmsActivityCreatedTopicGroupEmitter.next(body)
			}

			if (FormatUtil.text.toLowerCase(body.type) === 'avail_need_action') {
				pmsActivityCreatedTopicGroupEmitter.next(body)
			}

			if (FormatUtil.text.toLowerCase(body.type) === 'avail_need_order_created') {
				pmsActivityCreatedTopicGroupEmitter.next(body)
			}

			if (FormatUtil.text.toLowerCase(body.type) === 'avail_needs_basket_received') {
				pmsActivityCreatedTopicGroupEmitter.next(body)
			}

			if (FormatUtil.text.toLowerCase(body.type) === 'intraday_updated_inventory') {
				pmsActivityCreatedTopicGroupEmitter.next(body)
			}

			if (FormatUtil.text.toLowerCase(body.type) === 'restricted_security_document_uploaded') {
				pmsRestrictedSecurityDocumentUploadedEmitter.next(body)
			}

			if (FormatUtil.text.toLowerCase(body.type) === 'abcm_rerun') {
				pmsAbcmRerunEmitter.next(body)
			}

			if (FormatUtil.text.toLowerCase(body.type) === 'configuration_updated') {
				pmsConfigurationUpdateEmitter.next(body)
			}

			if (Object.keys(body).includes('pendingAbcm')) {
				pmsAbcmRerunEmitter.next(body)
			}

			pmsTopicGroupEmitter.next(notification)
		})
	}

	locateNotifier = groupId => {
		const topicGroupUrl = `/topic/group-${groupId}`
		this.locateClient.subscribe(topicGroupUrl, notification => {
			const body = JSON.parse(notification.body)
			if (
				[
					'LOCATE_REQUEST_CREATED',
					'LOCATE_REQUEST_ENTRY_AUTHORIZED',
					'LOCATE_REQUEST_UPDATED',
					'LOCATE_REQUEST_ENTRY_ACTION_TAKEN',
				].includes(body)
			) {
				locateActivityCreatedTopicGroupEmitter.next({type: body})
			}
		})
	}

	alpNotifier = groupId => {
		const topicGroupUrl = `/topic/group-${groupId}`
		this.alpClient.subscribe(topicGroupUrl, notification => {
			const body = JSON.parse(notification.body)
			if (['INVENTORY_UPDATED'].includes(body.type)) {
				alpActivityCreatedOnlyLocateTopicGroupEmitter.next(body)
			}
			if (['TRADE_UPDATED'].includes(body.type)) {
				alpActivityCreatedTopicGroupEmitter.next(body)
			}
		})

		// alpBatchTopicGroupUnsubscribeEmitter.subscribe({
		// 	next: () => {
		// 		this.alpBatchTopicGroupTriggerSubscriber && this.alpBatchTopicGroupTriggerSubscriber.unsubscribe()
		// 		this.alpBatchNotifier()
		// 	},
		// })

		const userTopicAlpUrl = `/topic/user-${getUserId()}`
		this.alpClient.subscribe(userTopicAlpUrl, notification => {
			alpTopicUserEmitter.next(notification)
		})
	}

	// alpBatchNotifier = () => {
	// 	this.alpBatchTopicGroupTriggerSubscriber = alpBatchTopicGroupTriggerEmitter.subscribe({
	// 		next: (batchId: string) => {
	// 			const topicBatchGroupUrl = `/topic/batch-${batchId}`
	// 			this.alpClient.subscribe(topicBatchGroupUrl, notification => {
	// 				const body = JSON.parse(notification.body)
	// 				if (body.type === 'INVENTORY_RECEIVED') {
	// 					alpBatchTopicGroupEmitter.next(batchId)
	// 				}
	// 			})
	// 		},
	// 	})
	// }

	auctionNotifier = () => {
		const auctionTopicGroupUrl = `/topic/tenantGroup-${getCounterpartyId()}`
		this.alpClient.subscribe(auctionTopicGroupUrl, notification => {
			const body = JSON.parse(notification.body)
			auctionTopicTenantGroupEmitter.next(body)
		})

		const auctionBorrowerTopicGroupUrl = `/topic/borrowerGroup-${getUserGroupId()}`
		this.alpClient.subscribe(auctionBorrowerTopicGroupUrl, notification => {
			const body = JSON.parse(notification.body)
			auctionTopicBorrowerGroupEmitter.next(body)
		})

		auctionTopicGroupUnsubscribeEmitter.subscribe({
			next: () => {
				this.auctionTopicGroupTriggerSubscriber && this.auctionTopicGroupTriggerSubscriber.unsubscribe()
				this.auctionAvailabilityNotifier()
			},
		})

		auctionUpdateTopicUnsubscribeEmitter.subscribe({
			next: () => {
				this.auctionUpdateTopicTriggerSubscriber && this.auctionUpdateTopicTriggerSubscriber.unsubscribe()
				this.auctionUpdateNotifier()
			},
		})
	}

	auctionAvailabilityNotifier = () => {
		this.auctionTopicGroupTriggerSubscriber = auctionTopicGroupTriggerEmitter.subscribe({
			next: (requestId: string) => {
				const topicBatchGroupUrl = `/topic/auction-${requestId}`
				this.alpClient.subscribe(topicBatchGroupUrl, notification => {
					const body = JSON.parse(notification.body)
					auctionTopicGroupEmitter.next({type: body.type, requestId})
				})
			},
		})
	}

	auctionUpdateNotifier = () => {
		this.auctionUpdateTopicTriggerSubscriber = auctionUpdateTopicTriggerEmitter.subscribe({
			next: (requestId: string) => {
				const auctionUpdateTopicUrl = `/topic/auctionUpdate-${requestId}`
				this.alpClient.subscribe(auctionUpdateTopicUrl, notification => {
					const body = JSON.parse(notification.body)
					auctionUpdateTopicGroupEmitter.next({type: body.type, requestId})
				})
			},
		})
	}

	render() {
		const {isConnectionLost} = this.state

		if (!isConnectionLost) return null

		return (
			<Toast
				animation
				className={`notification-wrapper bg-warning`}
				show={isConnectionLost}
				style={{position: 'fixed', top: 0, right: 0, minWidth: 270}}
				onClose={() => this.setState({isConnectionLost: false})}
			>
				<Toast.Header>
					<span className='d-block'>The connection has timed out.</span>
				</Toast.Header>
				<Toast.Body>
					<div className='d-flex align-items-center align-self-center'>
						<div className=''> Please refresh the page.</div>
						<button className='btn btn-xs btn-light mx-2 px-2' onClick={() => window.location.reload()}>
							Refresh
						</button>
					</div>
				</Toast.Body>
			</Toast>
		)
	}
}

