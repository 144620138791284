import {buildQuery} from 'axios-graphql-builder'

export const allOrderQuery = () => {
	const orderFields = {
		orderCreatedBy: null,
		allInRate: null,
		cashIndicator: null,
		creditRating: null,
		clientContractType: null,
		quantity: null,
		context: {
			displayStatus: null,
		},
		counterParties: null,
		counterPartiesIds: null,
		counterPartyLegalEntityId: null,
		collateralProvider: null,
		collateralReceiver: null,
		direction: null,
		extendableDate: null,
		indemnificationId: null,
		notional: {
			amount: null,
			currency: null,
		},
		notionalLimit: {
			amount: null,
			currency: null,
		},
		orderId: null,
		orderIndicatorType: null,
		orderResponseId: null,
		orderStage: null,
		orderType: null,
		ownerIndicator: null,
		term: null,
		time: null,
		tradeStatus: null,
		displayTime: null,
		indicator: null,
		cusip: null,
		schedule: null,
		collateralType: null,
		tradeDate: null,
		settlementDate: null,
		maturityDate: null,
		terminationDate: null,
		maturityType: null,
		margin: null,
		orderComments: null,
		rejectComments: null,
		createdBy: null,
		dirtyPrice: {
			amount: null,
		},
		startCash: {
			amount: null,
		},
		repoInterest: null,
		endCash: {
			amount: null,
		},
		spread: null,
		benchmark: null,
	}

	const tradeDetails = {
		orderCreatedBy: null,
		allInRate: null,
		cashIndicator: null,
		creditRating: null,
		clientContractType: null,
		context: {
			displayStatus: null,
		},
		collateralReceiver: null,
		collateralProvider: null,
		counterParties: null,
		counterPartyLegalEntityId: null,
		direction: null,
		extendableDate: null,
		extendableStatus: null,
		indemnificationId: null,
		notional: {
			amount: null,
			currency: null,
		},
		notionalLimit: {
			amount: null,
			currency: null,
		},
		orderId: null,
		orderIndicatorType: null,
		orderResponseId: null,
		orderStage: null,
		orderType: null,
		ownerIndicator: null,
		tctr: null,
		term: null,
		time: null,
		tradeStatus: null,
		displayTime: null,
		indicator: null,
		cusip: null,
		schedule: null,
		collateralType: null,
		tradeDate: null,
		settlementDate: null,
		maturityDate: null,
		settlementId: null,
		settlementMargin: null,
		settlementMarginStatus: null,
		terminationDate: null,
		maturityType: null,
		margin: null,
		createdBy: null,
		dirtyPrice: {
			amount: null,
		},
		startCash: {
			amount: null,
		},
		repoInterest: null,
		endCash: {
			amount: null,
		},
		quantity: null,
		spread: null,
		benchmark: null,
		accruedInterest: null,
	}

	const openOrders = {
		query: {
			openOrdersNew: orderFields,
		},
	}
	const myFirmActivity = {
		query: {
			myFirmActivityNew: orderFields,
		},
	}
	const openTrades = {
		query: {
			openTradesNew: tradeDetails,
		},
	}

	return [buildQuery(openOrders), buildQuery(myFirmActivity), buildQuery(openTrades)]
}

export const orderDetailQuery = orderId => {
	const paramList = [{key: 'order', param: `orderId: "${orderId}"`}]

	const bidOrOffer = {
		// actions: null,
		way: null,
		type: null,
		cash: {
			durationInSec: null,
			amount: null,
			currency: null,
			startCash: {
				amount: null,
				currency: null,
			},
			endCash: {
				amount: null,
				currency: null,
			},
			repoInterest: null,
			quantity: null,
		},
		collateral: {
			items: {
				status: null,
				ticker: null,
				cusip: null,
				isin: null,
				price: null,
				priceMultiplier: null,
				classification: null,
				quantity: null,
				basketQuantity: null,
				securityId: null,
				assetMaturityDate: null,
				assetType: null,
				schedule: null,
				cusipCode: null,
				scheduleDescription: null,
				margin: null,
				securityDescription: null,
				fitchRating: null,
				moodyRating: null,
				dirtyPrice: {
					amount: null,
				},
				startCash: {
					amount: null,
				},
				endCash: {
					amount: null,
				},
				repoInterest: null,
			},
		},
		singleStock: {
			securityId: null,
			assetType: null,
			securityDescription: null,
			cusipCode: null,
			cusip: null,
			schedule: null,
			isin: null,
			price: null,
			priceMultiplier: null,
			quantity: null,
			margin: null,
			scheduleDescription: null,
			dirtyPrice: {
				amount: null,
			},
			startCash: {
				amount: null,
			},
			endCash: {
				amount: null,
			},
			repoInterest: null,
		},
	}

	const orderDetail = {
		query: {
			order: {
				actions: null,
				clientRiskType: null,
				collateralType: null,
				clientContractType: null,
				extendableDate: null,
				modifiedDate: null,
				maturityType: null,
				allInRate: null,
				assetType: null,
				assetSubType: null,
				// benchmark: null, (to be added)
				bid: bidOrOffer,
				bidType: null,
				durationInSec: null,
				endCash: {
					amount: null,
				},
				index: null,
				indemnifications: {
					actions: null,
					activityLog: {
						createdDate: null,
						context: {
							direction: null,
							displayStatus: null,
						},
						indemnifier: null,
						indemnificationPrice: null,
						requestedByParty: null,
						status: null,
					},
					indemnificationId: null,
				},
				legalEntityExternalId: null,
				maturityDate: null,
				offer: bidOrOffer,
				offerType: null,
				price: {
					amount: null,
					currency: null,
				},
				referenceId: null,
				repoInterest: null,
				responses: {
					actions: null,
					activityLog: {
						allInRate: null,
						durationInSec: null,
						context: {
							direction: null,
							displayStatus: null,
						},
						price: {
							amount: null,
							currency: null,
						},
						status: null,
						spread: null,
					},
					responseId: null,
				},
				extendableResponses: {
					responseId: null,
					createdDate: null,
					status: null,
					applicableActions: null,
					newExtendableDate: null,
					newEndDate: null,
					activityLog: {
						responseId: null,
						status: null,
						newExtendableDate: null,
						newEndDate: null,
						createdBy: null,
						modifiedBy: null,
						createdDate: null,
						createdByEmail: null,
					},
					createdBy: null,
					createdByEmail: null,
				},
				extendableIndemnifications: {
					applicableActions: null,
					indemnificationId: null,
					createdDate: null,
					status: null,
					indemnificationPrice: null,
					indemnifier: null,
					requestedByParty: null,
					activityLog: {
						indemnificationId: null,
						activityId: null,
						createdDate: null,
						status: null,
						indemnificationPrice: null,
						createdBy: null,
						createdByEmail: null,
						indemnifier: null,
						requestedByParty: null,
					},
				},
				startCash: {
					amount: null,
				},
				settlementDate: null,
				spread: null,
				strategy: null,
				terminationDate: null,
				tradeDate: null,
				orderStage: null,
				rateType: null,
			},
		},
	}

	return buildQuery(orderDetail, paramList)
}

export const activityLogQuery = orderResponseId => {
	const paramList = [{key: 'orderCollaborationLogs', param: `orderResponseId: "${orderResponseId}"`}]

	const activityLog = {
		query: {
			orderCollaborationLogs: {
				time: null,
				title: null,
				activity: null,
				level: null,
			},
		},
	}

	return buildQuery(activityLog, paramList)
}

export const indemnificationActivityLogQuery = indemnificationId => {
	const paramList = [{key: 'indemnificationActivityLogs', param: `indemnificationId: "${indemnificationId}"`}]

	const activityLog = {
		query: {
			indemnificationActivityLogs: {
				createdDate: null,
				context: {
					displayStatus: null,
					detailedDisplayStatus: null,
					level: null,
				},
			},
		},
	}

	return buildQuery(activityLog, paramList)
}
