import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'
import {get} from '../../services/createAPICall'

export const GET_COLLATERAL_ELIGIBILITY_DATA = createFetchActions('GET_COLLATERAL_ELIGIBILITY_DATA')

export const getCollateralEligibilityData = () => {
	return function (dispatch: any) {
		dispatch(
			get(
				GET_COLLATERAL_ELIGIBILITY_DATA,
				`${getPMSBaseUrl()}/v1/collateral-eligibility/default`,
				null,
				null,
				'Internal Server Error occured while getting collateral eligibility matrix data'
			)
		)
	}
}
