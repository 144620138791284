import {
	GET_SUB_BUSINESS_UNITS,
	SAVE_SUB_BUSINESS_UNITS,
	EDIT_SUB_BUSINESS_UNITS,
	CLEAR_SUB_BUSINESS_UNITS,
} from './subBusinessUnitActions'
import {SubBusinessUnit} from './subBusinessUnitModel'

export interface SubBusinessUnitData {
	loading: boolean
	data: SubBusinessUnit[]
}

const initialState: SubBusinessUnitData = {
	loading: false,
	data: null,
}

export function subBusinessUnitReducer(state = initialState, action: any) {
	let data: SubBusinessUnit[] = []
	switch (action.type) {
		case GET_SUB_BUSINESS_UNITS.triggered:
			return {...state, loading: true}
		case GET_SUB_BUSINESS_UNITS.succeeded:
			data = (action.response.data && action.response.data.allSubBusinessUnits) || []
			return {...state, loading: false, data}
		case GET_SUB_BUSINESS_UNITS.failed:
			return {...state, loading: false}

		case SAVE_SUB_BUSINESS_UNITS.triggered:
			return {...state, loading: true}
		case SAVE_SUB_BUSINESS_UNITS.succeeded:
		case SAVE_SUB_BUSINESS_UNITS.failed:
			return {...state, loading: false}

		case EDIT_SUB_BUSINESS_UNITS.triggered:
			return {...state, loading: true}
		case EDIT_SUB_BUSINESS_UNITS.succeeded:
		case EDIT_SUB_BUSINESS_UNITS.failed:
			return {...state, loading: false}

		case CLEAR_SUB_BUSINESS_UNITS:
			return {...state, data: null}
	}

	return state
}
