import React from 'react'
import {ApiUtil, ObjectUtil} from 'helper-util'

import {HOMEPAGE} from './HomepageConstant'
import {Props, State} from './HomepageModel'
import {paths} from '../../../routes/routesConfig'
import {getCounterpartyId, getUserId, isAuthenticated} from '../../../services/authService'
import {v4 as uuidv4} from 'uuid'

export default class Homepage extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			defaultMenuConfig: {},
		}
	}

	componentDidMount() {
		const {getAllDataVisualizationDetails} = this.props

		if (isAuthenticated()) {
			getAllDataVisualizationDetails(HOMEPAGE)
				.then(result => {
					if (result && result.response && result.response.data) {
						const defaultMenuConfig = result.response.data[0] || {} // Set defaultMenuConfig
						!ObjectUtil.isEmpty(defaultMenuConfig) && this.setState({defaultMenuConfig}) // Update state with fetched data // Update state with fetched data
					}
				})
				.catch(error => {
					console.error('Error fetching data:', error)
				})
		}
	}

	onClick = () => {
		const {updateDataVisualizationDetails, createNewDataVisualizationDetails, setNotificationData} = this.props
		const {defaultMenuConfig} = this.state

		// defaultMenuConfig['screenId'] = HOMEPAGE
		// defaultMenuConfig['layoutId'] = uuidv4()

		if (ObjectUtil.isEmpty(defaultMenuConfig)) {
			const homepageObj = {
				layout: window.location.pathname,
				name: null,
				screenId: 'HOMEPAGE',
				tenantId: getCounterpartyId(),
				userLayoutId: uuidv4(),
				userUuid: getUserId(),
			}
			defaultMenuConfig['layout'] = window.location.pathname

			createNewDataVisualizationDetails(homepageObj).then(result => {
				if (ApiUtil.isSuccess(result)) {
					setNotificationData({
						title: 'Default Homepage Successfully Configured',
						message: `${window.location.pathname} is your default homepage`,
					})
				}
			})
		} else {
			defaultMenuConfig['layout'] = window.location.pathname

			updateDataVisualizationDetails(defaultMenuConfig).then(result => {
				if (ApiUtil.isSuccess(result)) {
					setNotificationData({
						title: 'Default Homepage Successfully Configured',
						message: `${window.location.pathname} is your default homepage`,
					})
				}
			})
		}
	}

	render() {
		const {wideNavbar} = this.props
		const {defaultMenuConfig} = this.state

		const currentLayout = window.location.pathname

		return wideNavbar && currentLayout !== paths.accessDenied ? (
			<div className='custom-title'>
				{defaultMenuConfig && (
					<>
						<span>
							{currentLayout === defaultMenuConfig['layout'] ? 'Default Homepage' : 'Set as Default Homepage'}
						</span>
						<i
							className={`${currentLayout === defaultMenuConfig['layout'] ? 'fas text-yellow' : 'far'} fa-star f-18`}
							onClick={currentLayout === defaultMenuConfig['layout'] ? () => {} : this.onClick}
						/>
					</>
				)}
			</div>
		) : null
	}
}

