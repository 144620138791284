import {
	SET_NAVBAR_TYPE,
	LOADER_START,
	LOADER_STOP,
	GET_PORTFOLIO_ALL_ENTRIES,
	GET_RESTRICTED_ENTRIES,
	GET_PORTFOLIO_SELECTED_ENTRIES,
	SET_DARK_THEME,
	SET_SUGGESTIONS_THEME,
	GET_EXECUTION_NOTIFICATIONS,
	GET_LOCATE_NOTIFICATIONS,
	GET_LOCATE_CLIENT_NOTIFICATIONS,
	AUTH_LOGOUT,
	GET_ALL_FUND,
	GET_ALL_PORTFOLIO,
	CLEAR_ALL_FUND,
	CLEAR_ALL_PORTFOLIO,
} from './appActions'
import {formatPortfolioAllEntries, addNewEntries, sumOfNotifications} from './appHelper'

export interface AppReducer {
	isLoading: boolean
	loader: boolean
	message: string
	windowHeight: number
	windowWidth: number
	wideNavbar: boolean
	portfolioAllEntries: any
	portfolioRestrictedEntries: any[]
	darkTheme: boolean
	suggestion: boolean
	executionNotifications: any
	locateNotifications: any
	locateClientNotifications: any
	allFund: any
	allPortfolio: any
}

const initialState: AppReducer = {
	isLoading: false,
	loader: false,
	message: '',
	windowHeight: window.innerWidth,
	windowWidth: window.innerHeight,
	wideNavbar: false,
	portfolioAllEntries: {},
	portfolioRestrictedEntries: [],
	darkTheme: null,
	suggestion: null,
	executionNotifications: {},
	locateNotifications: {},
	locateClientNotifications: {},
	allFund: null,
	allPortfolio: null,
}

export function appReducer(state = initialState, action: any): any {
	switch (action.type) {
		case SET_NAVBAR_TYPE:
			return {
				...state,
				wideNavbar: action.data,
			}
		case LOADER_START:
			return {
				...state,
				isLoading: true,
				message: action.data,
			}
		case LOADER_STOP:
			return {
				...state,
				isLoading: true,
				message: action.data,
			}

		case GET_PORTFOLIO_ALL_ENTRIES && GET_PORTFOLIO_ALL_ENTRIES.triggered:
			return {...state, isLoading: true, portfolioAllEntries: {}}
		case GET_PORTFOLIO_ALL_ENTRIES && GET_PORTFOLIO_ALL_ENTRIES.succeeded:
			const data = action.response
			const portfolioAllEntries = formatPortfolioAllEntries(data)
			return {...state, isLoading: false, portfolioAllEntries}
		case GET_PORTFOLIO_ALL_ENTRIES && GET_PORTFOLIO_ALL_ENTRIES.failed:
			return {...state, isLoading: false}

		case GET_PORTFOLIO_SELECTED_ENTRIES && GET_PORTFOLIO_SELECTED_ENTRIES.triggered:
			return {...state, loading: true}
		case GET_PORTFOLIO_SELECTED_ENTRIES && GET_PORTFOLIO_SELECTED_ENTRIES.succeeded:
			const newEntries = action.response
			const allEntries = addNewEntries(state.portfolioAllEntries, newEntries)
			return {...state, loading: false, portfolioAllEntries: allEntries}
		case GET_PORTFOLIO_SELECTED_ENTRIES && GET_PORTFOLIO_SELECTED_ENTRIES.failed:
			return {...state, loading: false}

		case GET_RESTRICTED_ENTRIES && GET_RESTRICTED_ENTRIES.triggered:
			return {...state, loading: true, portfolioRestrictedEntries: []}
		case GET_RESTRICTED_ENTRIES && GET_RESTRICTED_ENTRIES.succeeded:
			const portfolioRestrictedEntries = action.response
			return {...state, loading: false, portfolioRestrictedEntries}
		case GET_RESTRICTED_ENTRIES && GET_RESTRICTED_ENTRIES.failed:
			return {...state, loading: false}

		case SET_DARK_THEME:
			return {...state, darkTheme: action.response}
		case SET_SUGGESTIONS_THEME:
			return {...state, suggestion: action.response}

		case GET_EXECUTION_NOTIFICATIONS && GET_EXECUTION_NOTIFICATIONS.succeeded:
			const executionNotifications = {
				...state.executionNotifications,
				...state.locateNotifications,
				...action.response,
			}
			executionNotifications['total'] = sumOfNotifications(executionNotifications)
			return {...state, executionNotifications}

		case GET_LOCATE_NOTIFICATIONS && GET_LOCATE_NOTIFICATIONS.succeeded:
			const updatedExecutionNotifications = {
				...state.executionNotifications,
				...state.locateNotifications,
				...action.response.data,
			}
			const locateNotifications = action.response.data
			updatedExecutionNotifications['total'] = sumOfNotifications(updatedExecutionNotifications)
			return {...state, executionNotifications: updatedExecutionNotifications, locateNotifications}

		case GET_LOCATE_CLIENT_NOTIFICATIONS && GET_LOCATE_CLIENT_NOTIFICATIONS.succeeded:
			const locateClientNotifications = action.response.data
			const multiUpdatedExecutionNotifications = {
				...state.executionNotifications,
				...state.locateNotifications,
				...locateClientNotifications,
			}
			multiUpdatedExecutionNotifications['total'] = sumOfNotifications(multiUpdatedExecutionNotifications)
			return {...state, executionNotifications: multiUpdatedExecutionNotifications, locateClientNotifications}

		case AUTH_LOGOUT && AUTH_LOGOUT.triggered:
			return {...state, loading: true}
		case AUTH_LOGOUT && AUTH_LOGOUT.succeeded:
			return {...state, loading: false}
		case AUTH_LOGOUT && AUTH_LOGOUT.failed:
			return {...state, loading: false}

		case GET_ALL_FUND && GET_ALL_FUND.triggered:
			return {...state, loader: true}
		case GET_ALL_FUND && GET_ALL_FUND.succeeded:
			const allFund = action.response && action.response.data ? action.response.data.allFunds : []
			return {...state, loader: false, allFund}
		case GET_ALL_FUND && GET_ALL_FUND.failed:
			return {...state, loader: false, allFund: []}
		case CLEAR_ALL_FUND:
			return {...state, loader: false, allFund: null}

		case GET_ALL_PORTFOLIO && GET_ALL_PORTFOLIO.triggered:
			return {...state, loader: true}
		case GET_ALL_PORTFOLIO && GET_ALL_PORTFOLIO.succeeded:
			const allPortfolio = action.response && action.response.data ? action.response.data.allPortfolios : []
			return {...state, loader: false, allPortfolio}
		case GET_ALL_PORTFOLIO && GET_ALL_PORTFOLIO.failed:
			return {...state, loader: false, allPortfolio: []}
		case CLEAR_ALL_PORTFOLIO:
			return {...state, loader: false, allPortfolio: null}
	}
	return state
}

