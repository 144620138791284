import React from 'react'
import {Formik} from 'formik'
import * as yup from 'yup'
import {history} from '../../../store/mainStore'
import FsTextField from '../../../common/formItems/fsTextField'
import '../../login/login.scss'
import {forgotPasswordTitle} from '../forgotPasswordConstant'
import {redirectLoginWithTenantCode} from '../../../utils/amplify'

interface Props {
	onSubmit: Function
}

interface State {}

export default class PasswordResetRequestForm extends React.Component<Props, State> {
	render() {
		const {onSubmit} = this.props

		const validationSchema = yup.object().shape({
			email: yup.string().required('Username is Required'),
		})
		// email('Please Enter Valid Email') // Removed this validation because email changed to username
		return (
			<Formik
				validationSchema={validationSchema}
				initialValues={{
					email: '',
				}}
				onSubmit={values => onSubmit(values)}
			>
				{({values, errors, handleSubmit, handleChange, handleBlur}) => (
					<form onSubmit={handleSubmit}>
						<FsTextField
							usernameCharacterValidation
							id='email'
							autoComplete='off'
							label='Username'
							placeholder='Enter your username here'
							type='text'
							className='px-3'
							onChange={handleChange}
							value={values.email}
							onBlur={handleBlur}
						/>
						<button
							id='default_FsContainedButton'
							type='submit'
							className='btn btn-secondary btn-xl d-block w-100 mt-4 mb-4'
						>
							{forgotPasswordTitle.passwordResetRequest}
						</button>
						<div className='d-flex'>
							<span
								onClick={() => {
									history && redirectLoginWithTenantCode(history)
								}}
								className='link f-14 d-block text-center w-100 f-16'
							>
								Back
							</span>
						</div>
					</form>
				)}
			</Formik>
		)
	}
}
