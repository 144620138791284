import {updateRowData} from '../AlpSttAuthorizer/AlpSttAuthorizerHelper'
import {
	FETCH_REQUESTER_TRADES,
	CLEAR_MAIN_GRID_DATA,
	ALTER_RATE_FIELD_DATA,
	UPDATE_ROW_DATA_REQUESTER,
} from './AlpSttRequesterActions'
import {alterRateField, dataMassagingFn} from './AlpSttRequesterHelper'

export interface AlpSttRequester {
	loading: boolean
	gridLoading: boolean
	gridData: any
}

const initialState: AlpSttRequester = {
	loading: false,
	gridLoading: false,
	gridData: null,
}

export function AlpSttRequesterReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_REQUESTER_TRADES.triggered:
			return {...state, gridData: null, gridLoading: true}
		case FETCH_REQUESTER_TRADES.failed:
			return {...state, gridData: null, gridLoading: false}
		case FETCH_REQUESTER_TRADES.succeeded:
			return {...state, gridData: dataMassagingFn(action.response && action.response.data), gridLoading: false}

		case CLEAR_MAIN_GRID_DATA:
			return {
				...state,
				gridData: null,
			}

		case ALTER_RATE_FIELD_DATA:
			return {
				...state,
				gridData: alterRateField(state.gridData, action.payload.toggleMode),
			}

		case UPDATE_ROW_DATA_REQUESTER:
			return {
				...state,
				gridData: updateRowData(state.gridData, action.payload),
			}
	}
	return state
}
