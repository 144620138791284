// import gql from 'graphql-tag'

// const portfolio_entries_fragment = gql`
//   fragment AbcmPortfolioEntries on PortfolioEntry {
//     id
//     cusip
//     securityId
//     isin
//     ticker
//     ric
//     bbId
//     quantity
//     counterParty
//     assetType
//     assetSubType
//     price {
//       amount
//       currency
//     }
//     abcmCategoryName
//     abcmCategoryDisplayName
//     tradeType
//     book
//     fund
//     buySellInd
//     sourceSystem
//     sourceSystemTradeId
//     allInRate
//     spread
//     counterParty
//     collateralType
//     referenceRate
//     haircut
//     collateral {
//       amount
//       currency
//     }
//     notional {
//       amount
//       currency
//     }
//     baseNotional {
//       currency
//       amount
//     }
//     cash {
//       amount
//       currency
//     }
//     maturityDate
//     termDate
//     startDate
//     endDate
//     tradeDate
//     hqla
//     tenor
//     sector
//     index
//     moodyRating
//     snpRating
//     fitchRating
//     settlementStatus
//     settlementDate
//   }
// `

// const abcmResultPivotTableQuery = (portfolioId = '', rankingId = null, securityId = '') => {
//   rankingId = rankingId ? `"${rankingId}"` : null
//   securityId = securityId ? `"${securityId}"` : null
//   return gql`
// {
//   abcmResult(id: ${portfolioId}, rankingId: ${rankingId}, securityId: ${securityId}) {
//     id
//     allocatedQuantity
//     sourceAllocatedNotional
//     useAllocatedNotional
//     source {
//       ...AbcmPortfolioEntries
//     }
//     use {
//       ...AbcmPortfolioEntries
//     }
//   }
// }
// ${portfolio_entries_fragment}
// `
// }

const abcmResultPivotTableQuery = (portfolioId = '', rankingId = null, securityId = '') => {
	rankingId = rankingId ? `"${rankingId}"` : null
	securityId = securityId ? `"${securityId}"` : null
	return `
{
  abcmResult(id: ${portfolioId}, rankingId: ${rankingId}, securityId: ${securityId}) {
    id
    allocatedQuantity
    pnl
    duration
    balanceSheetContribution{
      amount
      currency
    }
    sourceAllocatedNotional {
      amount
      currency
    }
    useAllocatedNotional {
      amount
      currency
    }
    source {
      id
      allocationName
      assetTarget
      businessCode
    cusip
    duration
    balanceSheetContribution{
      amount
      currency
    }
    securityId
    isin
    ticker
    originalTradeType
    legalEntity
    ric
    bbId
    quantity
    counterParty
    assetType
    assetSubType
    exchangeRate
    price {
      amount
      currency
    }
    abcmCategoryType
    abcmCategoryName
    abcmCategoryDisplayName
    tradeType
    book
    fund
    buySellInd
    sourceSystem
    sourceSystemTradeId
    allInRate
    spread
    counterParty
    collateralType
    maturityType
    referenceRate
    haircut
    collateral {
      amount
      currency
    }
    notional {
      amount
      currency
    }
    baseNotional {
      currency
      amount
    }
    cash {
      amount
      currency
    }
    maturityDate
    termDate
    startDate
    endDate
    tradeDate
    hqla
    tenor
    sector
    index
    moodyRating
    snpRating
    fitchRating
    settlementStatus
    settlementDate
    }
    use {
      id
      allocationName
      assetTarget
      businessCode
    cusip
    duration
    balanceSheetContribution{
      amount
      currency
    }
    securityId
    isin
    ticker
    originalTradeType
    legalEntity
    ric
    bbId
    quantity
    counterParty
    assetType
    assetSubType
    price {
      amount
      currency
    }
    abcmCategoryType
    abcmCategoryName
    abcmCategoryDisplayName
    tradeType
    book
    fund
    buySellInd
    sourceSystem
    sourceSystemTradeId
    allInRate
    spread
    counterParty
    collateralType
    maturityType
    referenceRate
    haircut
    collateral {
      amount
      currency
    }
    notional {
      amount
      currency
    }
    baseNotional {
      currency
      amount
    }
    cash {
      amount
      currency
    }
    maturityDate
    termDate
    startDate
    endDate
    tradeDate
    hqla
    tenor
    sector
    index
    moodyRating
    snpRating
    fitchRating
    settlementStatus
    settlementDate
    }
  }
}
`
}

const abcmResultPivotTableQueryForCPTY = (portfolioId = '', source = null, cpty = '') => {
	source = source ? `"${source}"` : null
	cpty = cpty ? `"${cpty}"` : null
	return `
{
abcmResultCounterParty(sourceUseFlag: ${source}, counterParty: ${cpty}, id: ${portfolioId}){
  id
  allocatedQuantity
  pnl
  duration
  balanceSheetContribution{
  amount
  currency
  }
  sourceAllocatedNotional {
  amount
  currency
  }
  useAllocatedNotional {
  amount
  currency
  }
  source {
  id
  cusip
  duration
  balanceSheetContribution{
  amount
  currency
  }
  securityId
  isin
  ticker
  originalTradeType
  legalEntity
  ric
  bbId
  quantity
  counterParty
  assetType
  assetSubType
  exchangeRate
  price {
  amount
  currency
  }
  abcmCategoryType
  abcmCategoryName
  abcmCategoryDisplayName
  tradeType
  book
  fund
  buySellInd
  sourceSystem
  sourceSystemTradeId
  allInRate
  spread
  counterParty
  collateralType
  maturityType
  referenceRate
  haircut
  collateral {
  amount
  currency
  }
  notional {
  amount
  currency
  }
  baseNotional {
  currency
  amount
  }
  cash {
  amount
  currency
  }
  maturityDate
  termDate
  startDate
  endDate
  tradeDate
  hqla
  tenor
  sector
  index
  moodyRating
  snpRating
  fitchRating
  settlementStatus
  settlementDate
  }
  use {
  id
  cusip
  duration
  balanceSheetContribution{
  amount
  currency
  }
  securityId
  isin
  ticker
  originalTradeType
  legalEntity
  ric
  bbId
  quantity
  counterParty
  assetType
  assetSubType
  price {
  amount
  currency
  }
  abcmCategoryType
  abcmCategoryName
  abcmCategoryDisplayName
  tradeType
  book
  fund
  buySellInd
  sourceSystem
  sourceSystemTradeId
  allInRate
  spread
  counterParty
  collateralType
  maturityType
  referenceRate
  haircut
  collateral {
  amount
  currency
  }
  notional {
  amount
  currency
  }
  baseNotional {
  currency
  amount
  }
  cash {
  amount
  currency
  }
  maturityDate
  termDate
  startDate
  endDate
  tradeDate
  hqla
  tenor
  sector
  index
  moodyRating
  snpRating
  fitchRating
  settlementStatus
  settlementDate
  }
  }
}
`
}
export {abcmResultPivotTableQuery, abcmResultPivotTableQueryForCPTY}
