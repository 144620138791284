import React from 'react'
import { FinoptsysLogo, VenturiLogoLargeLong, VenturiLogoLong } from '../styles/svg/svgIcons'



export const DynamicLogo = logo => {
  const flag=['demo', 'qa', 'stg'].includes(process.env.REACT_APP_ENV);
  const finoptsysLogo=(<div className='header-logo-color text-center f-24'>FinOptSys</div>)

  const venturiLogo= (<div className='header-logo-color text-center f-24'>
                         <VenturiLogoLong />
                      </div>)

const finoptsysLoginLogo=(<FinoptsysLogo />)

const venturiLoginLogo=(<div className='header-logo-color text-center mb-4'>
                          <VenturiLogoLargeLong />
                        </div>)
  
  if(logo==='logo')
	return (
   flag?finoptsysLogo:venturiLogo
	)

  else if(logo==='loginLogo')
    return(
     flag?finoptsysLoginLogo:venturiLoginLogo 
  )
  else
  return null
}
