export const permission = {
	READ_PORTFOLIO_DOCUMENT: 'R_PRTF_FILE',
	WRITE_PORTFOLIO_DOCUMENT: 'RW_PRTF_FILE',
	READ_PORTFOLIO: 'R_ABCP',
	WRITE_PORTFOLIO: 'RW_ABCP',
	READ_PORTFOLIO_ABCM: 'R_ABCM',
	WRITE_PORTFOLIO_ABCM: 'RW_ABCM',
	READ_COLLATERAL_BASKET: 'R_COL_BSKT',
	WRITE_COLLATERAL_BASKET: 'RW_COL_BSKT',
	READ_ORG_SETUP: 'R_ORG',
	WRITE_ORG_SETUP: 'RW_ORG',
	READ_RESTRICTED_SECURITY_DOCUMENT: 'R_RES_FILE',
	WRITE_RESTRICTED_SECURITY_DOCUMENT: 'RW_RES_FILE',
	READ_P2P: 'R_P2P',
	WRITE_P2P: 'RW_P2P',
	READ_AVAILABILITY_NEEDS_DOCUMENT: 'R_AVN',
	WRITE_AVAILABILITY_NEEDS_DOCUMENT: 'RW_AVN',
	WRITE_COF: 'RW_KPI',
	WRITE_SOD: 'RW_KPI',
	LOCATE_SEEKER: 'REQ_ALP',
	LOCATE_PROVIDER: 'AUTH_ALP',
	AUCTION_SEEKER: 'REQ_AUCTION',
	AUCTION_PROVIDER: 'AUTH_AUCTION',
	READ_TEMPLATE_MANAGEMENT: 'R_TMPLT',
	WRITE_TEMPLATE_MANAGEMENT: 'RW_TMPLT',
	FEATURES_IN_BETA: 'BETA',
	READ_OPERATIONS: 'R_OPRNS',
	WRITE_OPERATIONS: 'RW_OPRNS',
	READ_DATA_MINING: 'R_BI',
	FEATURES_DEPRECATED: 'DEPCR',
	READ_SBL: 'R_SBL',
	WRITE_SBL: 'RW_SBL',
	READ_REPO: 'R_REPO',
	WRITE_REPO: 'RW_REPO',
	READ_COLLATERAL_OPTIMIZER: 'R_COL_OPT',
	WRITE_COLLATERAL_OPTIMIZER: 'RW_COL_OPT',
	READ_CLIENT_SCORING: 'R_CLNT_SCORE',
	WRITE_CLIENT_SCORING: 'RW_CLNT_SCORE',
	READ_DECISION_MAKING: 'R_DMS',
	WRITE_DECISION_MAKING: 'RW_DMS',
	READ_INVENTORY_OPTIMIZER: 'R_INV_OPT',
	WRITE_INVENTORY_OPTIMIZER: 'RW_INV_OPT',
	WRITE_USER: 'RW_USER',
	READ_MARGIN_MANAGEMENT: 'R_MRGN',
	WRITE_MARGIN_MANAGEMENT: 'RW_MRGN',
	READ_MARGIN_ANALYSIS: 'R_MGAN',
	WRITE_MARGIN_ANALYSIS: 'RW_MGAN',
	READ_MARGIN_ATTRIBUTION: 'R_MGAT',
	WRITE_MARGIN_TTRIBUTION: 'RW_MGAT',
	READ_TRADE_BLOTTER: 'R_TRD_BLTR',
	WRITE_TRADE_BLOTTER: 'RW_TRD_BLTR',
	READ_CALENDER: 'R_CLDR',
	WRITE_CALENDER: 'RW_CLDR',
	READ_SETTLEMENT_LADDER: 'R_STLDR',
	WRITE_SETTLEMENT_LADDER: 'RW_STLDR',
	READ_REPO_DBRD: 'R_REPO_DBRD',
	WRITE_REPO_DBRD: 'RW_REPO_DBRD',
	READ_TRADING_QUEUE: 'R_TRDQ',
	WRITE_TRADING_QUEUE: 'RW_TRDQ',
	READ_SBL_EFF: 'R_SBL_EFF',
	READ_SWAP_MARKETPLACE: 'R_SP2P',
	WRITE_SWAP_MARKETPLACE: 'RW_SP2P',
}

export const modules = {
	dashboard: 'Dashboard',
	collateralBasket: 'Collateral Basket',
	settlementCalendar: 'Maturity Calendar',
	settlementLadder: 'Settlement Ladder',
	marketPlace: 'Peer to Peer',
	inventory: 'Inventory',
	tradingQueue: 'Trading Queue',
	inventoryOptimization: 'Inventory Optimization',
	portfolioManager: 'Portfolio Manager',
	accountSettings: 'Account Settings',
	abcmAnalytics: 'ABCM Analytics',
	abcPortfolio: 'ABC Portfolio',
	repoPricer: 'Pricing',
	decisionMaking: 'Decision Making',
	needsAvailabilities: 'Availabilities & Needs',
	marginAnalysis: 'Margin Analysis',
}
