import {positionsData, positionsDrilldownData} from '../../pages/primeBroker/pbDashboard/pbDashboardConstants'
import {
	GET_COMMON_DASHBOARD_ENTITIES_AMOUNT,
	GET_DASHBOARD_PORTFOLIO_ANALYTICS,
	RESET_DASHBOARD_DATA,
	SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE,
	SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE,
	SET_TRADE_BY,
	GET_NOTIFICATIONS,
	GET_ALL_TRADES_FOR_DASHBOARD,
	HEATMAP_DASHBOARD_DATA,
	HEATMAP_MATURE_DATA,
	SET_SCREEN_TYPE,
	FETCH_COLLATERAL_OPTIMIZATION_ENTITY_DATA,
	FETCH_COLLATERAL_OPTIMIZATION_SUMMARY_DATA,
	GET_MARKET_TRADE_DATA_FROM_DASHBOARD,
	HEATMAP_POSITIONS_DATA,
	HEATMAP_DRILLDOWN_POSITIONS_DATA,
	HEATMAP_MARGIN_DATA,
	HEATMAP_DRILLDOWN_MARGIN_DATA,
	GET_BUSINESS_CODE_WITH_RATES,
	GET_SBL_ENHANCED_DASHBOARD_OVERVIEW_AND_RERATE,
} from './dashboardActions'
import {addAmountsFromObjects} from './dashboardHelper'

export interface Dashboard2 {
	heatmapLoading: boolean
	heatmapLoadingSBL: boolean
	loading: boolean
	loadingEmptyValue: boolean
	firstLevelTreemapValue: string
	secondLevelTreemapValue: string
	dashboardEntityAmountDataLoading: boolean
	dashboardEntityAmountData: any
	dashboardPortfolioAnalyticsLoading: boolean
	dashboardPortfolioAnalytics: any
	tradeBy: any
	notifications: any
	tradeData: any
	tradesDataLoading: boolean
	heatmapData: any[]
	heatmapBoxData: any[]
	screenType: string
	collateralOptimizationComputedDate: any
	orders: any
	loadingOrders: boolean
	businessCodesWithRates: any[]
	responseData: any
}

const initialState: Dashboard2 = {
	heatmapLoading: false,
	heatmapLoadingSBL: false,
	loading: false,
	loadingEmptyValue: false,
	firstLevelTreemapValue: '',
	secondLevelTreemapValue: '',
	dashboardEntityAmountDataLoading: false,
	dashboardEntityAmountData: {},
	dashboardPortfolioAnalyticsLoading: false,
	dashboardPortfolioAnalytics: [{}, {}],
	tradeBy: null,
	notifications: [],
	tradeData: [],
	tradesDataLoading: false,
	heatmapData: [],
	heatmapBoxData: [],
	screenType: '',
	collateralOptimizationComputedDate: null,
	orders: [],
	loadingOrders: false,
	businessCodesWithRates: [],
	responseData: {},
}
export function dashboardReducer2(state = initialState, action: any) {
	const collateralOptimizationEntityAmountData = state.dashboardEntityAmountData
	switch (action.type) {
		case SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE:
			const firstLevelTreemapValue = action.response.data
			return {...state, firstLevelTreemapValue}

		case SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE:
			const secondLevelTreemapValue = action.response.data
			return {...state, secondLevelTreemapValue}

		case SET_SCREEN_TYPE:
			const screenType = action && action.response && action.response.screenType
			return {...state, screenType: screenType || 'main'}

		case GET_COMMON_DASHBOARD_ENTITIES_AMOUNT.triggered:
			return {
				...state,
				dashboardEntityAmountDataLoading: true,
				loading: true,
			}
		case GET_COMMON_DASHBOARD_ENTITIES_AMOUNT.succeeded:
			const dashboardEntityAmountData = state.dashboardEntityAmountData
			const responseData = action.response.data || {}
			dashboardEntityAmountData[state.screenType || 'main'] = action.response.data
			if (state.screenType === 'sbl') {
				let entityData = dashboardEntityAmountData[state.screenType || 'main'].sblDashboard.overview
				dashboardEntityAmountData[state.screenType || 'main'].sblDashboard.overview['sblTradesSbl'] = entityData.loans
				dashboardEntityAmountData[state.screenType || 'main'].sblDashboard.overview['sblTradesBorrows'] =
					entityData.borrows
				dashboardEntityAmountData[state.screenType || 'main'].sblDashboard.overview['loanOpenTrades'] =
					entityData.loanOpenTrades
				dashboardEntityAmountData[state.screenType || 'main'].sblDashboard.overview['borrowOpenTrades'] =
					entityData.borrowOpenTrades
				dashboardEntityAmountData[state.screenType || 'main'].sblDashboard.overview['loanReRate'] =
					entityData.loanReRate
				dashboardEntityAmountData[state.screenType || 'main'].sblDashboard.overview['borrowReRate'] =
					entityData.borrowReRate
				dashboardEntityAmountData[state.screenType || 'main'].sblDashboard.overview['loanMatureTrades'] =
					entityData.loanMatureTrades
				dashboardEntityAmountData[state.screenType || 'main'].sblDashboard.overview['borrowMatureTrades'] =
					entityData.borrowMatureTrades
			}

			return {
				...state,
				responseData,
				dashboardEntityAmountData: dashboardEntityAmountData,
				dashboardEntityAmountDataLoading: false,
				loading: state.dashboardPortfolioAnalyticsLoading,
			}

		case GET_SBL_ENHANCED_DASHBOARD_OVERVIEW_AND_RERATE.triggered:
			return {
				...state,
				dashboardEntityAmountDataLoading: true,
				loading: true,
			}
		case GET_SBL_ENHANCED_DASHBOARD_OVERVIEW_AND_RERATE.succeeded:
			const dashboardEntityAmountData2 = state.dashboardEntityAmountData
			const responseDataCopy = action.response.data || {}
			dashboardEntityAmountData2[state.screenType || 'main'] = action.response.data
			if (state.screenType === 'sbl') {
				const dashboardOverviewRes = action.response.data
				let dashboardEntityAmountDataCopy = !!dashboardEntityAmountData2 ? dashboardEntityAmountData2 : {}

				dashboardEntityAmountDataCopy = dashboardEntityAmountDataCopy.hasOwnProperty('sblDashboard')
					? dashboardEntityAmountDataCopy
					: {
							sblDashboard: {
								...dashboardOverviewRes,
							},
					  }

				dashboardEntityAmountDataCopy = dashboardEntityAmountDataCopy.sblDashboard.hasOwnProperty('overview')
					? dashboardEntityAmountDataCopy
					: {
							sblDashboard: {
								overview: {
									...dashboardOverviewRes,
								},
							},
					  }

				dashboardEntityAmountDataCopy.sblDashboard.overview['sblTradesSbl'] = dashboardOverviewRes.loans
				dashboardEntityAmountDataCopy.sblDashboard.overview['sblTradesBorrows'] = dashboardOverviewRes.borrows
				dashboardEntityAmountDataCopy.sblDashboard.overview['loanOpenTrades'] = dashboardOverviewRes.loanOpenTrades
				dashboardEntityAmountDataCopy.sblDashboard.overview['borrowOpenTrades'] = dashboardOverviewRes.borrowOpenTrades
				dashboardEntityAmountDataCopy.sblDashboard.overview['loanReRate'] = dashboardOverviewRes.loanReRate
				dashboardEntityAmountDataCopy.sblDashboard.overview['borrowReRate'] = dashboardOverviewRes.borrowReRate
				dashboardEntityAmountDataCopy.sblDashboard.overview['loanMatureTrades'] = dashboardOverviewRes.loanMatureTrades
				dashboardEntityAmountDataCopy.sblDashboard.overview['borrowMatureTrades'] =
					dashboardOverviewRes.borrowMatureTrades

				dashboardEntityAmountData2[state.screenType || 'main'] = dashboardEntityAmountDataCopy
			}

			return {
				...state,
				responseData: responseDataCopy,
				dashboardEntityAmountData: dashboardEntityAmountData2,
				dashboardEntityAmountDataLoading: false,
				loading: state.dashboardPortfolioAnalyticsLoading,
			}
		case GET_DASHBOARD_PORTFOLIO_ANALYTICS.triggered:
			return {
				...state,
				dashboardPortfolioAnalytics: state.dashboardPortfolioAnalytics,
				dashboardPortfolioAnalyticsLoading: true,
				heatmapLoading: true,
				loading: true,
			}
		case GET_DASHBOARD_PORTFOLIO_ANALYTICS.succeeded:
			const {response} = action
			const dashboardPortfolioAnalytics = [
				{...state.dashboardPortfolioAnalytics[0], ...response[0]},
				{...state.dashboardPortfolioAnalytics[1], ...response[1]},
			]
			return {
				...state,
				dashboardPortfolioAnalytics,
				dashboardPortfolioAnalyticsLoading: false,
				heatmapLoading: false,
				loading: state.dashboardEntityAmountDataLoading,
			}

		case RESET_DASHBOARD_DATA:
			return {
				...state,
				dashboardEntityAmountData: {},
				dashboardPortfolioAnalytics: [{}, {}],
				loading: false,
			}

		case SET_TRADE_BY.triggered:
			return {...state, tradeBy: null, loading: true}
		case SET_TRADE_BY.succeeded:
			let tradeBy = JSON.parse(JSON.stringify(state.tradeBy))
			const tradeByList = action.response
			Object.keys(tradeByList).forEach(key => {
				if (key && tradeByList[key]) {
					const tradingQueueSummary = tradeByList[key].tradingQueueSummary
					if (tradeBy === null) tradeBy = {}
					tradingQueueSummary.forEach(summaryDetail => {
						const counterParty = summaryDetail.counterParty
						const userSummaries = summaryDetail.userSummaries
						if (!tradeBy[key]) tradeBy[key] = {}
						let tradeByText = `\n\nMoved by: `
						userSummaries.forEach(summary => {
							tradeByText += `\n${summary.userEmail} (${summary.entriesCount})`
						})
						tradeBy[key][counterParty] = tradeByText
					})
				}
			})
			return {
				...state,
				tradeBy,
				loading: false,
			}
		case SET_TRADE_BY.failed:
			return {...state, loading: false}

		case GET_NOTIFICATIONS.triggered:
			return {...state, loading: true}
		case GET_NOTIFICATIONS.succeeded:
			return {...state, loading: false, notifications: action.response.data}
		case GET_NOTIFICATIONS.failed:
			return {...state, loading: false}
		case GET_ALL_TRADES_FOR_DASHBOARD.triggered:
			return {...state, tradesDataLoading: true}
		case GET_ALL_TRADES_FOR_DASHBOARD.failed:
			return {...state, tradesDataLoading: false}
		case GET_ALL_TRADES_FOR_DASHBOARD.succeeded:
			state.tradeData = action.response.data ? addAmountsFromObjects(action.response.data.portfolio) : []
			return {
				...state,
				tradesDataLoading: false,
			}

		case HEATMAP_DASHBOARD_DATA.triggered:
			return {
				...state,
				heatmapLoading: true,
				heatmapLoadingSBL: true,
				loadingEmptyValue: false,
				loading: true,
				heatmapData: [],
			}
		case HEATMAP_DASHBOARD_DATA.succeeded:
			return {
				...state,
				heatmapLoading: false,
				heatmapLoadingSBL: false,
				loadingEmptyValue: true,
				loading: false,
				heatmapData: action.response.data,
			}
		case HEATMAP_DASHBOARD_DATA.failed:
			return {...state, heatmapLoading: false, heatmapLoadingSBL: false, loading: false, heatmapData: []}
		case HEATMAP_POSITIONS_DATA.triggered:
			return {...state, heatmapLoading: true, loading: true, heatmapData: []}
		case HEATMAP_POSITIONS_DATA.succeeded:
			return {...state, heatmapLoading: false, loading: false, heatmapData: action.response.data}
		case HEATMAP_POSITIONS_DATA.failed:
			return {...state, heatmapLoading: false, loading: false, heatmapData: positionsData}
		case HEATMAP_DRILLDOWN_POSITIONS_DATA.triggered:
			return {...state, heatmapLoading: true, loading: true, heatmapData: []}
		case HEATMAP_DRILLDOWN_POSITIONS_DATA.succeeded:
			return {...state, heatmapLoading: false, loading: false, heatmapData: action.response.data}
		case HEATMAP_DRILLDOWN_POSITIONS_DATA.failed:
			return {...state, heatmapLoading: false, loading: false, heatmapData: positionsDrilldownData}
		case HEATMAP_MARGIN_DATA.triggered:
			return {...state, heatmapLoading: true, loading: true, heatmapData: []}
		case HEATMAP_MARGIN_DATA.succeeded:
			return {
				...state,
				heatmapLoading: false,
				loading: false,
				heatmapData: action.response.data,
			}
		case HEATMAP_MARGIN_DATA.failed:
			return {...state, heatmapLoading: false, loading: false, heatmapData: []}
		case HEATMAP_DRILLDOWN_MARGIN_DATA.triggered:
			return {...state, heatmapLoading: true, loading: true, heatmapData: []}
		case HEATMAP_DRILLDOWN_MARGIN_DATA.succeeded:
			return {
				...state,
				heatmapLoading: false,
				loading: false,
				heatmapData: action.response.data,
			}
		case HEATMAP_DRILLDOWN_MARGIN_DATA.failed:
			return {...state, heatmapLoading: false, loading: false, heatmapData: []}

		case HEATMAP_MATURE_DATA.triggered:
			return {...state, heatmapLoading: true, loading: true, heatmapData: []}
		case HEATMAP_MATURE_DATA.succeeded:
			return {...state, heatmapLoading: false, loading: false, heatmapData: action.response.data}
		case HEATMAP_MATURE_DATA.failed:
			return {...state, heatmapLoading: false, loading: false, heatmapData: []}

		case FETCH_COLLATERAL_OPTIMIZATION_ENTITY_DATA.triggered:
			return {...state, heatmapLoading: true, loading: true, heatmapData: []}

		case FETCH_COLLATERAL_OPTIMIZATION_ENTITY_DATA.succeeded:
			collateralOptimizationEntityAmountData[state.screenType || 'main'] = action.response.data
				? action.response.data.overview.data
				: {}
			return {
				...state,
				heatmapLoading: false,
				loading: false,
				dashboardEntityAmountData: collateralOptimizationEntityAmountData,
			}

		case FETCH_COLLATERAL_OPTIMIZATION_ENTITY_DATA.failed:
			return {
				...state,
				heatmapLoading: false,
				loading: false,
				dashboardEntityAmountData: {},
			}

		case FETCH_COLLATERAL_OPTIMIZATION_SUMMARY_DATA.triggered:
			return {...state, heatmapLoading: true, loading: true, heatmapData: []}

		case FETCH_COLLATERAL_OPTIMIZATION_SUMMARY_DATA.succeeded:
			const collateralOptimizationSummary = action.response.data ? action.response.data.summary : []
			const collateralOptimizationComputedDate = action.response.data ? action.response.data.lastUpdateTime : null
			return {
				...state,
				heatmapLoading: false,
				loading: false,
				heatmapData: collateralOptimizationSummary,
				collateralOptimizationComputedDate,
			}

		case FETCH_COLLATERAL_OPTIMIZATION_SUMMARY_DATA.failed:
			return {
				...state,
				heatmapLoading: false,
				loading: false,
				heatmapData: [],
			}

		case GET_MARKET_TRADE_DATA_FROM_DASHBOARD.triggered:
			return {...state, loadingOrders: true}
		case GET_MARKET_TRADE_DATA_FROM_DASHBOARD.failed:
			return {...state, loadingOrders: false}
		case GET_MARKET_TRADE_DATA_FROM_DASHBOARD.succeeded:
			const orders = action.response.data ? action.response.data.orders : []
			return {...state, loadingOrders: false, orders}

		case GET_BUSINESS_CODE_WITH_RATES.triggered:
			return {...state, loading: true, businessCodesWithRates: []}
		case GET_BUSINESS_CODE_WITH_RATES.succeeded:
			return {...state, loading: false, businessCodesWithRates: action.response.data}
		case GET_BUSINESS_CODE_WITH_RATES.failed:
			return {...state, loading: false, businessCodesWithRates: []}
	}

	return state
}
