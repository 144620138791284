import {GET_TRADES} from './TradeBlotterDynamicActions'

export interface TradeBlotterDynamic {
	allTrades: any[]
}

const initialState: TradeBlotterDynamic = {
	allTrades: null,
}

export function tradeBlotterDynamicReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_TRADES.triggered:
			return {...state, allTrades: null, finalizeUpload: null}
		case GET_TRADES.succeeded:
			return {...state, allTrades: action.response.data}
		case GET_TRADES.failed:
			return {...state, allTrades: []}
	}

	return state
}
