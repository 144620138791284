import {DateUtil} from 'helper-util'
import moment from 'moment-timezone'
import {getDateFromArray} from '../../../utils/dateUtil'
import {addBusinessDays} from '../../PeerToPeer/OrderEntryModal/OrderEntryModalHelper'
import {
	mandatoryFieldForOutrightCp,
	mandatoryFieldsForOutrightTsy,
	mandatoryFieldsForRepoSBL,
} from './NewTradeModalConstant'

export const formReducer = (state, action) => {
	switch (action.type) {
		case 'UPDATE_FORM':
			const {name, val} = action.data
			return {
				...state,
				// update the state of the particular field,
				// by retaining the state of other fields
				[name]: {...state[name], val},
			}
		default:
			return state
	}
}

export const onChange = (name: string, val: any, dispatch) => {
	dispatch({
		type: 'UPDATE_FORM',
		data: {name, val},
	})
}

export const disableCreateBtn = tradeDetails => {
	const mandatoryFields =
		tradeDetails.strategy && tradeDetails.strategy.value === 'OUTRIGHT_CP'
			? mandatoryFieldForOutrightCp
			: tradeDetails.strategy && tradeDetails.strategy.value === 'OUTRIGHT_TSY'
			? mandatoryFieldsForOutrightTsy
			: mandatoryFieldsForRepoSBL

	return mandatoryFields.some(
		field => tradeDetails[field] === null || (typeof tradeDetails[field] === 'number' && tradeDetails[field] === 0)
	)
}

export const roundUpToQuater = value => {
	return (Math.round(value * 4) / 4).toFixed(2)
}

export const updateDateFormat = details => {
	for (const key of Object.keys(details)) {
		const date = details[key]
		if (moment.isMoment(date)) details[key] = moment(date).format('YYYY-MM-DD')
	}

	return details
}

export const getFormInitialState = holidays => {
	return {
		buySellInd: null,
		collateralType: 'Cash',
		counterParty: null,
		dividendPercentage: null,
		settlementMode: null,
		strategy: null,
		rateType: null,
		allInRate: null,
		benchmark: null,
		referenceId: null,
		spread: null,
		schedule: null,
		quantity: null,
		price: null,
		haircut: 0,
		cleanPrice: null,
		dirtyPrice: null,
		depo: null,
		startCash: null,
		repoInterest: null,
		endCash: null,
		notional: null,
		currency: null,
		security: null,
		securityDescription: null,
		callable: {id: 'n', label: 'N', value: false},
		callableDate: null,
		impliedAllInRate: null,
		puttable: {id: 'n', label: 'N', value: false},
		puttableDate: null,
		tradeDate: DateUtil.getNextWeekday(getDateFromArray([])),
		settlementDate: holidays
			? addBusinessDays(DateUtil.getNextWeekday(getDateFromArray([])), 1, holidays)
			: DateUtil.getNextWeekday(getDateFromArray([])),
		maturityDate: holidays
			? addBusinessDays(DateUtil.getNextWeekday(getDateFromArray([])), 1, holidays)
			: DateUtil.getNextWeekday(getDateFromArray([])),
		terminationDate:  holidays
    ? addBusinessDays(DateUtil.getNextWeekday(getDateFromArray([])), 1, holidays)
    : DateUtil.getNextWeekday(getDateFromArray([])),
		maturityType: null,
		refRate: null,
		legalEntityExternalId: null,
	}
}

export const validDateHoliday = holidays => {
	const tempDate = moment().clone().startOf('day')
	return !(![0, 6].includes(tempDate.day()) && !holidays.find(holiday => moment(holiday).isSame(tempDate)))
}
