/* eslint-disable no-eval */
import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import {ArrayUtil, ObjectUtil} from 'helper-util'
import moment from 'moment-timezone'

import {FieldProp} from './DynamicFormComponent'
import {excludeWeekend} from './DatePicker/DatePickerHelper'
import {formatWithAbbreviations} from '../../utils/formatUtils'

export const extractData = memoizeOne(dynamicFormDetail => {
	if (ObjectUtil.isEmpty(dynamicFormDetail) || ArrayUtil.isEmpty(dynamicFormDetail.templates)) return {}

	const formulaBasedData = []
	const defaultTradeData = {}
	const fieldsList = {}
	dynamicFormDetail.templates.forEach(template => {
		defaultTradeData[template.id] = {}
		template &&
			!ArrayUtil.isEmpty(template.fields) &&
			template.fields.forEach((field: FieldProp) => {
				if (field.formula) {
					const item = {
						fieldId: field.id,
						formula: field.formula,
						templateId: template.id,
						type: field.type,
						...field,
					}
					formulaBasedData.push(item)
				}
				fieldsList[field.header] = {value: template.id, key: field.id}
				defaultTradeData[template.id][field.id] = field.defaultValue
			})
	})
	const allSubTemplateIds = dynamicFormDetail.templates.filter(item => item.optionalTemplate).map(item => item.id) || []
	const tradeData = updateData(defaultTradeData, formulaBasedData, null, true)

	return {formulaBasedData, defaultTradeData: tradeData, allSubTemplateIds, fieldsList}
}, isDeepEqual)

export const updateData = (tradeData, formulaBasedData, updatedFieldId, init = false) => {
	let newTradeData = JSON.parse(JSON.stringify(tradeData))
	formulaBasedData.forEach(item => {
		const initializing = updatedFieldId === null
		const updateFormulaBasedExcludingOriginField =
			item && item.formula.includes(updatedFieldId) && item.fieldId !== updatedFieldId

		if (initializing || updateFormulaBasedExcludingOriginField) {
			const formulaBasedValue = init && item.defaultValue ? item.defaultValue : handleEvalExp(newTradeData, item)
			if (newTradeData[item.templateId][item.fieldId] !== formulaBasedValue) {
				newTradeData[item.templateId][item.fieldId] = formulaBasedValue
				newTradeData = updateData(newTradeData, formulaBasedData, item.fieldId, init)
			} else {
				return newTradeData
			}
		}
	})

	return newTradeData
}

export const handlePayload = (templateId: string, fieldId: string, fieldValue: any, defaultPayload: any) => {
	const newPayload = defaultPayload ? defaultPayload.value : {}

	if (!newPayload[templateId]) newPayload[templateId] = {}
	newPayload[templateId][fieldId] = fieldValue

	defaultPayload.emitter(newPayload, fieldId)
}

export const splitField = (wideFieldSize, fieldDetail) => {
	const split = wideFieldSize ? 3 : 6
	const length = fieldDetail.length

	const splittedField = []
	for (let i = 0; i < Math.ceil(length / split); i++) {
		let sliced = fieldDetail.slice(i * split, (i + 1) * split)
		if (sliced.length < split)
			sliced = [...sliced, ...Array(split - sliced.length).fill({id: null, type: null, field: null})]
		splittedField.push(sliced)
	}

	return splittedField
}
const formulaEvalExp = (combinedPayload, formula) => {
	return formula
		.match(/[^<>]+/g)
		.map(formulaItem => (combinedPayload.hasOwnProperty(formulaItem) ? combinedPayload[formulaItem] : formulaItem))
		.join('')
}
export const handleEvalExp = (payload, item) => {
	let combinedPayload = {}
	const opening = "combinedPayload['"
	const closing = "']"
	const {type, formula, lookup} = item

	Object.values(payload).forEach((itemValue: any) => {
		combinedPayload = Object.assign(combinedPayload, itemValue)
	})

	const exp = formula.replace(/</g, opening).replace(/>/g, closing)
	const matchEvalExp = formulaEvalExp(combinedPayload, formula)

	switch (type) {
		//  sample = '<field_name> vs <field_name>'
		//  sample = 'lookup: {
		// 	             Pay: '<gnrl:psnhdr:entity_code>',
		// 	             Receive: null,
		//             },'

		case 'text':
			let evaluatedValue

			if (lookup) {
				const evalExpResult = eval(exp)
				evaluatedValue = lookup[evalExpResult] || null

				const matchEvalExpWithReplacements =
					lookup[matchEvalExp] && lookup[matchEvalExp].includes('<')
						? lookup[matchEvalExp].replace(/</g, opening).replace(/>/g, closing)
						: null

				evaluatedValue = matchEvalExpWithReplacements ? eval(matchEvalExpWithReplacements) || null : evaluatedValue
			} else {
				evaluatedValue = matchEvalExp
					.split(' ')
					.map(field => (field.includes('$') ? formatWithAbbreviations(field) : field))
					.join(' ')
			}
			return evaluatedValue || null

		// sample = '<field_name> * <field_name> * 100'
		//  sample = 'lookup: {
		// 	             Pay: '<field_name> * <field_name> * 100',
		// 	             Receive: null,
		//             },'
		case 'number':
			let expression
			if (lookup) {
				expression = formulaEvalExp(combinedPayload, lookup[eval(exp)])
			} else {
				expression = matchEvalExp
			}
			const matchEvalExpArr = expression.split(' ')
			let evaluate = true
			matchEvalExpArr.forEach(items => {
				if (!['(', ')', '+', '-', '*', '/'].includes(items) && !items) evaluate = false
			})

			if (moment.isMoment(moment(matchEvalExpArr[0])) && moment.isMoment(moment(matchEvalExpArr[2]))) {
				// sample expression - (2024-02-29 - 2024-02-28)
				if (matchEvalExpArr[1] === '-') {
					const daysDiff = moment(matchEvalExpArr[0]).diff(moment(matchEvalExpArr[2]), 'days')
					return Number(daysDiff).toString() || item.defaultValue
				}
			}

			return evaluate ? eval(expression) : combinedPayload[item.header] || item.defaultValue || null

		// sample '<field_name>' and '<field_name> --EOM'
		case 'dropdown':
			// TODO: AS-8018
			if (exp.includes('--EOM')) {
				let date = exp.split(' ')[0]
				return date === moment(new Date(date)).endOf('month').format('YYYY-MM-DD').toString()
					? 'EoM'
					: moment(eval(date)).date()
			}
			return lookup ? lookup[eval(exp)] || null : eval(exp)

		// sample '<date_field> 3 Weeks thursday --daySelector'
		// sample '<date_field> + <field_name> + 2 days or <field_name> + 2 days' or '<date_field>'
		//  sample = 'lookup: {
		// 	             Pay: '<date_field> 3 Weeks thursday --daySelector',
		// 	             Receive: '<date_field> + <field_name> + 2 days or <field_name> + 2 days' or '<date_field>',
		//             },'
		case 'date':
			const {dateFormat = 'YYYYMMDD'} = item

			let formula
			if (lookup) {
				formula = formulaEvalExp(combinedPayload, lookup[eval(exp)])
			} else {
				formula = matchEvalExp
			}

			const splitEvalExp = formula.split(' ')
			const daySelectorFlag = splitEvalExp.includes('--daySelector')

			if (daySelectorFlag) {
				const dynamicFieldValue = Number(splitEvalExp[0]) || 0 // number
				const dynamicFieldMonth = splitEvalExp[1] || 'months' // 'months'
				const targetedWeekValue = (Number(splitEvalExp[2]) || 0) - 1 // number && number > 0
				const targetedWeek = splitEvalExp[3] || 'weeks' // 'weeks'
				const targetedDayName = splitEvalExp[4] || 'thursday' // 'sunday' || 'monday' || 'tuesday' || 'wednesday' || 'thursday' || 'friday' || 'saturday'
				return moment()
					.add(dynamicFieldValue, dynamicFieldMonth)
					.date(1)
					.add(targetedWeekValue, targetedWeek)
					.day(targetedDayName)
					.format(dateFormat)
					.toString()
			} else {
				const dynamicFieldValue = splitEvalExp[0] // dateFormat
				splitEvalExp.shift()
				let date = moment(dynamicFieldValue).format(dateFormat)
				while (!ArrayUtil.isEmpty(splitEvalExp)) {
					const targetedArrithematic = splitEvalExp[0] === '-' ? 'subtract' : 'add' // '-' or '+'
					date = moment(date)[`${targetedArrithematic}`](splitEvalExp[1], splitEvalExp[2]) //splitEvalExp[2] can be day(s)/month(s)/week(s)/year(s)
					splitEvalExp.splice(0, 3)
				}
				return dynamicFieldValue && moment(excludeWeekend(date)).format(dateFormat).toString()
			}

		case 'switch':
			return lookup ? lookup[eval(exp)] || null : eval(exp)
	}
}

