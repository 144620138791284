import {CLIENT_CONSTANTS} from '../../constants/clientConfig'

export const allowedWss = {
	[CLIENT_CONSTANTS.FINOPTSYS]: {
		MPS: true,
		PMS: true,
		LOCATE: true,
		ALP: false,
		MNA: false,
	},
	[CLIENT_CONSTANTS.TRADEUP]: {
		MPS: true,
		PMS: true,
		LOCATE: false,
		ALP: false,
		MNA: false,
	},
	[CLIENT_CONSTANTS.DH]: {
		MPS: true,
		PMS: true,
		LOCATE: true,
		ALP: false,
	},
	[CLIENT_CONSTANTS.STATESTREET]: {
		MPS: true,
		PMS: true,
		LOCATE: false,
		ALP: false,
		MNA: true,
	},
	[CLIENT_CONSTANTS.STATESTREET_ALP]: {
		MPS: false,
		PMS: false,
		LOCATE: false,
		ALP: true,
		MNA: false,
	},
	default: {
		MPS: false,
		PMS: false,
		LOCATE: false,
		ALP: false,
		MNA: false,
	},
}
