import {FormatUtil} from 'helper-util'
import moment from 'moment-timezone'

import {
	alpActivityCreatedTopicTenantEmitter,
	pmsTopicGroupEmitter,
	pmsUserQueueNotificationEmitter,
} from './webSocketEmitter'
import {mpsTenantTopicOrderNotificationEmitter, pmsActivityCreatedTopicGroupEmitter} from './webSocketEmitter'
import {pmsRestrictedSecurityDocumentUploadedEmitter, pmsAbcmRerunEmitter} from './webSocketEmitter'
import {pmsConfigurationUpdateEmitter, locateActivityCreatedTopicGroupEmitter} from './webSocketEmitter'
import {alpActivityCreatedTopicGroupEmitter, alpActivityCreatedOnlyLocateTopicGroupEmitter} from './webSocketEmitter'
import {getP2pActionLabel} from '../../pages/tradingQueue/myFirmActivity/recallReurnRequest/recallReturnRequestHelper'
import {getObjectFromStorage, getStringFromStorage} from '../../services/storageService'
import {locateNotificationContent} from '../../pages/Locate/LocateAuthorizer/LocateAuthorizerHelper'
import {checkFeaturePermission} from '../../utils/featureUtils'
import {permission} from '../../constants/permission'
import {isReadWritePortfolioPermission} from '../../app/appHelper'
import {activeClientConfig} from '../../services/authService'
import {CLIENT_CONSTANTS} from '../../constants/clientConfig'
import {isExecutionNotificationPermission} from '../navigationBar/navigationBarHelper'
import Lodash from 'lodash'

const subscriber = {}

export const hasAvnPermission = () =>
	checkFeaturePermission(permission.READ_AVAILABILITY_NEEDS_DOCUMENT) ||
	checkFeaturePermission(permission.WRITE_AVAILABILITY_NEEDS_DOCUMENT)

export const initializeGlobalSubscriber = (action, setNotificationData) => {
	subscriber['mpsTenantTopicOrderNotification'] = mpsTenantTopicOrderNotificationEmitter.subscribe({
		next: (notification: any) => {
			const {getExecutionNotifications} = action

			isExecutionNotificationPermission() && getExecutionNotifications()
		},
	})

	subscriber['pmsTopicGroup'] = pmsTopicGroupEmitter.subscribe({
		next: (notification: any) => {
			const processedPortfolioObject = JSON.parse(notification.body)
			const {getExecutionNotifications, setAbcmRerunTiming} = action

			const {type} = processedPortfolioObject
			const isDashboardReady = FormatUtil.text.toLowerCase(type) === 'dashboard_ready'
			const isCompleted = FormatUtil.text.toLowerCase(type) === 'completed'
			const pendingAbcmStatus = getObjectFromStorage('pendingAbcmStatus')
			const {time} = notification
			if (
				time &&
				pendingAbcmStatus &&
				pendingAbcmStatus.time &&
				moment(time).diff(moment(pendingAbcmStatus.time)) > 0
			) {
				setAbcmRerunTiming(notification)
			}
			const abcmReadyMessage = isCompleted ? `\n ABCM is ready now \n Dashboard & ABC Portfolio are in progress` : ''

			const groudpIdOfConsolidatedUser = processedPortfolioObject.portfolioDTO
				? processedPortfolioObject.portfolioDTO.groupId
				: ''

			if (type && groudpIdOfConsolidatedUser) {
				if (!isDashboardReady) {
					setNotificationData({
						message: `Portfolio ${type}\n ${abcmReadyMessage}`,
					})
				}
				if (isCompleted && isReadWritePortfolioPermission()) {
					action.getPortfolioStatus()
				}
			}

			if (isDashboardReady) {
				action.getPortfolioStatus()
				setNotificationData({
					message: `Dashboard & ABC Portfolio are ready now`,
				})
			}

			isExecutionNotificationPermission() && getExecutionNotifications()
		},
	})

	subscriber['pmsActivityCreatedTopicGroup'] = pmsActivityCreatedTopicGroupEmitter.subscribe({
		next: (notification: any) => {
			const {counterParty, type, requestType, activityType, actions, incoming} = notification
			const {
				getPortfolioAllEntries,
				getTradingQueueData,
				getBasketDetails,
				reloadFundCashFlowData,
				getExecutionNotifications,
			} = action

			if (FormatUtil.text.toLowerCase(type) === 'trading_queue') {
				getTradingQueueData()
			}

			if (FormatUtil.text.toLowerCase(type) === 'incoming_activity_created') {
				setNotificationData({
					title: 'Incoming Request',
					message: `A New Recall/Return request`,
				})
				// getTradingQueueData()
				if (isReadWritePortfolioPermission()) {
					getPortfolioAllEntries() //todo may not be needed in future
				}
				reloadFundCashFlowData(true)
			}

			if (FormatUtil.text.toLowerCase(type) === 'outgoing_activity_created') {
				setNotificationData({
					title: 'Outgoing Request',
					message: `A new ${activityType ? activityType : ''} request was created`,
				})
				// getTradingQueueData()
				if (isReadWritePortfolioPermission()) {
					getPortfolioAllEntries() //todo may not be needed in future
				}
				reloadFundCashFlowData(true)
			}

			if (FormatUtil.text.toLowerCase(type) === 'activity_action') {
				setNotificationData({
					title: incoming ? 'Incoming Message' : 'Outgoing Message',
					message: `${activityType ? activityType : ''} request was ${getP2pActionLabel(actions)}`,
				})
				// getTradingQueueData()
				if (isReadWritePortfolioPermission()) {
					getPortfolioAllEntries() //todo
				}
				reloadFundCashFlowData(true)
			}

			if (FormatUtil.text.toLowerCase(type) === 'avail_need_action') {
				setNotificationData({
					title: 'The Request was initated',
					message: `The Request was ${getP2pActionLabel(actions)}`,
				})

				hasAvnPermission() && getBasketDetails()
			}

			if (FormatUtil.text.toLowerCase(type) === 'avail_need_order_created') {
				const requestLabel = requestType === 'BORROW_REQUEST' ? 'Borrow' : 'Loan'
				setNotificationData({
					title: `A  ${requestLabel} was created`,
					message: `A ${requestLabel} Order was created`,
				})

				hasAvnPermission() && getBasketDetails()
			}

			if (FormatUtil.text.toLowerCase(type) === 'avail_needs_basket_received') {
				setNotificationData({
					title: `Incoming Availabilities File`,
					message: `Incoming Availabilities File`,
				})

				hasAvnPermission() && getBasketDetails()
			}

			if (FormatUtil.text.toLowerCase(type) === 'intraday_updated_inventory') {
				// const {documentIdSecuritySetMap} = notification
				const {securitiesUpdatedCount} = notification
				const {getPortfolioDocsIncluded, getPortfolioDocsAll} = action

				// const document = ObjectUtil.isEmpty(documentIdSecuritySetMap)
				// 	? ''
				// 	: Object.keys(documentIdSecuritySetMap).join(', ')
				securitiesUpdatedCount &&
					setNotificationData({
						title: 'Securities Updated',
						message: `${securitiesUpdatedCount} ${
							securitiesUpdatedCount > 1 ? 'securities have' : 'security has'
						} been updated.`,
					})

				getPortfolioDocsIncluded(null, null)
				getPortfolioDocsAll(null, null, 1)
				hasAvnPermission() && getBasketDetails()
			}

			if (FormatUtil.text.toLowerCase(type) === 'activity_created') {
				const isRecalled = FormatUtil.text.toLowerCase(activityType) === 'recalled'
				const message = `${
					isRecalled ? 'Recall' : 'Return'
				} initiated for ${counterParty} \n Portfolio entries will be refreshed shortly`

				setNotificationData({message})
				// getTradingQueueData()
			}

			isExecutionNotificationPermission() && getExecutionNotifications()
		},
	})

	subscriber['pmsRestrictedSecurityDocumentUploadedTopicGroup'] =
		pmsRestrictedSecurityDocumentUploadedEmitter.subscribe({
			next: (notification: any) => {
				const {uploadedBy} = notification
				const {getRestrictedEntries} = action
				setNotificationData({
					message: `Restricted Security Document uploaded by ${uploadedBy}`,
				})

				getRestrictedEntries()
			},
		})

	subscriber['pmsConfigurationUpdateTopicGroup'] = pmsConfigurationUpdateEmitter.subscribe({
		next: (notification: any) => {
			const {configurationType} = notification
			const {getCostOfFunds, getCashPositionAndFlow} = action

			if (FormatUtil.text.toLowerCase(configurationType) === 'cof') {
				setNotificationData({
					title: `COF has been updated`,
					message: `COF has been updated`,
				})
				getCostOfFunds()
			}
			if (FormatUtil.text.toLowerCase(configurationType) === 'sod_cashflow') {
				setNotificationData({
					title: `SOD cashflow has been updated`,
					message: `SOD cashflow has been updated`,
				})
				getCashPositionAndFlow()
			}
		},
	})

	subscriber['pmsAbcmRerunTopicGroup'] = pmsAbcmRerunEmitter.subscribe({
		next: (notification: any) => {
			const {setAbcmRerunTiming, getPortfolioAllEntries, reloadFundCashFlowData, getCashPositionAndFlow} = action
			const pendingAbcmStatus = getObjectFromStorage('pendingAbcmStatus')
			const {time} = notification
			if (
				time &&
				pendingAbcmStatus &&
				pendingAbcmStatus.time &&
				moment(time).diff(moment(pendingAbcmStatus.time)) > 0
			) {
				setAbcmRerunTiming(notification)
			}
			if (isReadWritePortfolioPermission()) {
				getPortfolioAllEntries()
			}
			getCashPositionAndFlow()
			reloadFundCashFlowData(true)
		},
	})

	subscriber['pmsUserQueueNotification'] = pmsUserQueueNotificationEmitter.subscribe({
		next: notification => {},
	})

	subscriber['locateActivityCreatedTopicGroup'] = locateActivityCreatedTopicGroupEmitter.subscribe({
		next: (notification: any) => {
			const {type} = notification
			const {
				fetchLocateAuthorizerDetails,
				fetchLocateRequesterDetails,
				getLocateNotifications,
				getLocateClientNotifications,
			} = action
			if (
				[
					'LOCATE_REQUEST_CREATED',
					'LOCATE_REQUEST_ENTRY_AUTHORIZED',
					'LOCATE_REQUEST_UPDATED',
					'LOCATE_REQUEST_ENTRY_ACTION_TAKEN',
				].includes(type)
			) {
				const clientConfig = getStringFromStorage(activeClientConfig)

				if ([CLIENT_CONSTANTS.FINOPTSYS, CLIENT_CONSTANTS.DH].includes(clientConfig)) {
					if (checkFeaturePermission(permission.LOCATE_PROVIDER)) {
						fetchLocateAuthorizerDetails()
						getLocateNotifications()
					}
					if (checkFeaturePermission(permission.LOCATE_SEEKER)) {
						fetchLocateRequesterDetails()
						getLocateClientNotifications()
					}
					locateNotificationContent && setNotificationData(locateNotificationContent(type))
				}
			}
		},
	})

	const getInventoryAndTradesFn = Lodash.debounce((notification: any) => {
		const {type, tradeUpdated} = notification
		const parsedTradeData = JSON.parse(tradeUpdated)
		const {updateRowDataAuthorizer, updateRowDataRequester, getKPISummary} = action
		console.log('Inside alp websocket subscriber')
		if (['TRADE_UPDATED'].includes(type)) {
			if (checkFeaturePermission(permission.LOCATE_PROVIDER)) {
				updateRowDataAuthorizer({...parsedTradeData, rowJustUpdated: true})
				setTimeout(() => {
					updateRowDataAuthorizer({...parsedTradeData, rowJustUpdated: false})
				}, 3000)
			}
			if (checkFeaturePermission(permission.LOCATE_SEEKER)) {
				updateRowDataRequester({...parsedTradeData, rowJustUpdated: true})
				setTimeout(() => {
					updateRowDataRequester({...parsedTradeData, rowJustUpdated: false})
				}, 3000)
			}
			getKPISummary()
		}
	}, 100)

	subscriber['alpActivityCreatedTopicGroup'] = alpActivityCreatedTopicGroupEmitter.subscribe({
		next: getInventoryAndTradesFn,
	})

	subscriber['alpActivityCreatedTopicGroupOnlyLocate'] = alpActivityCreatedOnlyLocateTopicGroupEmitter.subscribe({
		next: (notification: any) => {
			const {type} = notification
			const {getTargetedInventory} = action
			console.log('Inside alp websocket subscriber - Only locate')
			if (['INVENTORY_UPDATED'].includes(type)) {
				getTargetedInventory()
			}
		},
	})

	subscriber['alpActivityCreatedTopicTenant'] = alpActivityCreatedTopicTenantEmitter.subscribe({
		next: (notification: any) => {
			const {fetchAllSecurities} = action
			fetchAllSecurities()
		},
	})
}

export const unsubscribeAllGlobally = subscriptions => {
	if (subscriptions.constructor === Object) {
		Object.values(subscriptions).forEach((subscription: any) => {
			subscription.unsubscribe()
		})
	}
}

export const unsubscribe = () => {
	unsubscribeAllGlobally(subscriber)
}
