import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl, getPMSGraphBaseUrl} from '../../services/apiService'
import {getSecuritySuggestionsQuery} from './SblSecurityQuery'
import {graphQlWithAxios} from '../../services/createAPICall'
import {get} from '../../services/createAPICall'

export const GET_SBL_SEC_AVAILABILITY_DATA = createFetchActions('GET_SBL_SEC_AVAILABILITY_DATA')
export const GET_SECURITY_SUGGESTIONS = createFetchActions('GET_SECURITY_SUGGESTIONS')
export const GET_LEGAL_ENTITY_SBL_DATA = createFetchActions('GET_LEGAL_ENTITY_SBL_DATA')
export const GET_SBL_SEC_LOANS_AND_BORROWS_DATA = createFetchActions('GET_SBL_SEC_LOANS_AND_BORROWS_DATA')

export const getSecurityBasedAvailabilities = (securityId: any) => (dispatch: any) => {
	return dispatch(
		get(GET_SBL_SEC_AVAILABILITY_DATA, `${getPMSBaseUrl()}/v1/single-security-view/${securityId}/availabilities`)
	)
}

export const getLegalEntities = () => (dispatch: any) => {
	return dispatch(get(GET_LEGAL_ENTITY_SBL_DATA, `${getPMSBaseUrl()}/v1/single-security-view/distinct/legalEntity`))
}

export const getSecuritySuggestionsResult = keyword => {
	const query = getSecuritySuggestionsQuery(keyword)
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_SECURITY_SUGGESTIONS, baseURL, query, response => {
			const securityDataArray =
				response && response.data && response.data.data ? response.data.data.securitySuggestions : []
			dispatch({
				type: GET_SECURITY_SUGGESTIONS.succeeded,
				response: securityDataArray,
			})
		})
	}
}

export const getLoansAndBorrowDetails = (securityId: any, legalEntity: any) => (dispatch: any) => {
	const baseUrl = `${getPMSBaseUrl()}/v1/single-security-view/${securityId}`
	const legalEntityUrl = `?legalEntity=${legalEntity}`
	const url = legalEntity ? baseUrl + legalEntityUrl : baseUrl

	return dispatch(get(GET_SBL_SEC_LOANS_AND_BORROWS_DATA, url))
}
