import {GET_MY_ACCOUNTS, EDIT_MY_ACCOUNTS, SAVE_MY_ACCOUNTS, CLEAR_ACCOUNTS_DATA} from './myAccountsActions'
import {MyAccounts} from './myAccountsModel'

export interface MyAccountsData {
	loading: boolean
	data: any[]
}

const initialState: MyAccountsData = {
	loading: false,
	data: null,
}

export function myAccountsReducer(state = initialState, action: any) {
	let data: MyAccounts[] = []
	switch (action.type) {
		case GET_MY_ACCOUNTS.triggered:
			return {...state, loading: true}
		case GET_MY_ACCOUNTS.succeeded:
			data = (action.response.data && action.response.data.allAccounts) || []
			return {...state, loading: false, data}
		case GET_MY_ACCOUNTS.failed:
			return {...state, loading: false}

		case SAVE_MY_ACCOUNTS.triggered:
			return {...state, loading: true}
		case SAVE_MY_ACCOUNTS.succeeded:
		case SAVE_MY_ACCOUNTS.failed:
			return {...state, loading: false}

		case EDIT_MY_ACCOUNTS.triggered:
			return {...state, loading: true}
		case EDIT_MY_ACCOUNTS.succeeded:
		case EDIT_MY_ACCOUNTS.failed:
			return {...state, loading: false}

		case CLEAR_ACCOUNTS_DATA.triggered:
			return {...state, loading: true, data: null}
		case CLEAR_ACCOUNTS_DATA.succeeded:
		case CLEAR_ACCOUNTS_DATA.failed:
			return {...state, loading: false}

		case CLEAR_ACCOUNTS_DATA:
			return {...state, data: null}
	}

	return state
}
