import moment from 'moment-timezone'
import memoizeOne from 'memoize-one'

export const excludeWeekend = memoizeOne(currentValue => {
	const day = moment(currentValue).day()
	let newValue = currentValue
	switch (day) {
		case 0:
			newValue = moment(currentValue).add(1, 'days').format('YYYY-MM-DD')
			break
		case 6:
			newValue = moment(currentValue).add(2, 'days').format('YYYY-MM-DD')
			break
	}
	return newValue
})

export const isWeekday = memoizeOne(date => {
	const day = new Date(date).getDay()
	return day !== 0 && day !== 6
})

export const getMinDate = memoizeOne((param, allFields) => {
	return param && param.minDate && !moment(param.minDate, 'YYYY/MM/DD', true).isValid()
		? allFields[param.minDate]
		: param.minDate
})

export const getMaxDate = memoizeOne((param, allFields) => {
	return param && param.maxDate && !moment(param.maxDate, 'YYYY/MM/DD', true).isValid()
		? allFields[param.maxDate]
		: param.maxDate
})
