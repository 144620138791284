import gql from 'graphql-tag'

const portfolio_entries_fragment = gql`
	fragment DasboardPortfolioEntries on PortfolioEntry {
		actions
		activityType
		allInRate
		assetClassification
		assetMaturityDate
		assetSubType
		assetType
		availableQuantity
		balanceSheetContribution {
			amount
			currency
		}
		baseNotional {
			amount
			currency
		}
		basePrice {
			amount
			currency
		}
		bbId
		book
		borrowFee
		legalEntity
		loanFee
		buySellInd
		collateralType
		collateral {
			amount
			currency
		}
		counterParty
		createdBy
		createdDate
		cusip
		effectiveBaseNotional {
			amount
			currency
		}
		endDate
		exchangeRate
		fitchRating
		fund
		haircut
		hqla
		id
		inefficiencies {
			inefficiency
			abbr
			displayName
		}
		index
		isin
		maturityDate
		maturityType
		moodyRating
		needQuantity
		notional {
			amount
			currency
		}
		originalTradeType
		poolFactor
		portfolioEntryType
		partial
		price {
			amount
			currency
		}
		priceMultiplier
		quantity
		recallInefficiencyContribution {
			amount
			currency
		}
		recallQuantity
		referenceRate
		returnInefficiencyContribution {
			amount
			currency
		}
		returnQuantity
		availabilitiesContribution {
			amount
			currency
		}
		needsContribution {
			amount
			currency
		}
		platformTradeId
		rebate
		ric
		sector
		securityId
		securityIdType
		settlementDate
		snpRating
		sourceSystem
		sourceSystemTradeId
		spread
		startDate
		tenor
		termDate
		tradeDate
		ticker
		tradeType
	}
`

const portfolio_entries_re_rate_fragment = gql`
	fragment DasboardPortfolioEntries on ReRateEntry {
		actions
		abcmCategoryName
		activityType
		allInRate
		assetClassification
		assetMaturityDate
		assetSubType
		assetType
		availableQuantity
		balanceSheetContribution {
			amount
			currency
		}
		baseNotional {
			amount
			currency
		}
		basePrice {
			amount
			currency
		}
		bbId
		book
		borrowFee
		loanFee
		buySellInd
		collateralType
		collateral {
			amount
			currency
		}
		counterParty
		createdBy
		createdDate
		cusip
		effectiveBaseNotional {
			amount
			currency
		}
		endDate
		exchangeRate
		fitchRating
		fund
		haircut
		hqla
		id
		inefficiencies {
			inefficiency
			abbr
			displayName
		}
		index
		isin
		maturityDate
		maturityType
		moodyRating
		needQuantity
		notional {
			amount
			currency
		}
		poolFactor
		portfolioEntryType
		partial
		price {
			amount
			currency
		}
		priceMultiplier
		quantity
		recallInefficiencyContribution {
			amount
			currency
		}
		recallQuantity
		referenceRate
		returnInefficiencyContribution {
			amount
			currency
		}
		returnQuantity
		availabilitiesContribution {
			amount
			currency
		}
		needsContribution {
			amount
			currency
		}
		platformTradeId
		rebate
		ric
		sector
		securityId
		securityIdType
		settlementDate
		snpRating
		sourceSystem
		sourceSystemTradeId
		spread
		startDate
		tenor
		termDate
		tradeDate
		ticker
		tradeType
	}
`

const dashboardEntityAmountQuery = gql`
	query {
		repoDashboard {
			repoOverview {
				reRate {
					amount
					currency
					message
				}
				matureTrades {
					amount
					currency
					message
				}
				openTrades {
					amount
					currency
					message
				}
				repoTrades {
					amount
					currency
					message
				}
				reRate {
					amount
					currency
					message
				}
				reverseRepoTrades {
					amount
					currency
					message
				}
				needs {
					amount
					currency
					message
				}
				availabilities {
					amount
					currency
					message
				}
			}
		}
	}
`

const repoTradesRepoDataQuery = gql`
	query {
		repoDashboard {
			allTrades {
				counterParty
				isRegNetting
				balanceSheetContribution {
					amount
					currency
				}
				matchedMaturity {
					amount
					currency
				}
				repo {
					amount
					currency
				}
				reverse {
					amount
					currency
				}
				exposure {
					amount
					currency
				}
				notional {
					amount
					currency
				}
				entries {
					...DasboardPortfolioEntries
				}
				repoAdjustment {
					amount
					currency
				}
				reverseAdjustment {
					amount
					currency
				}
				repoDiff {
					amount
					currency
				}
				reverseDiff {
					amount
					currency
				}
				modifiedBy
				lastUpdated
			}
		}
	}
	${portfolio_entries_fragment}
`
const repoTradesReverseDataQuery = repoTradesRepoDataQuery

const matureTradesDataQuery = gql`
	query {
		repoDashboard {
			matureTrades {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const openTradesDataQuery = gql`
	query {
		repoDashboard {
			openTrades {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const reRateDataQuery = gql`
	query {
		repoDashboard {
			reRate {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_re_rate_fragment}
`

const availabilitiesDataQuery = gql`
	query {
		repoDashboard {
			availabilities {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const needsDataQuery = gql`
	query {
		repoDashboard {
			needs {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const dashboardPortfolioAnalyticsQuery = {
	repoTradesRepoDataQuery,
	repoTradesReverseDataQuery,
	matureTradesDataQuery,
	openTradesDataQuery,
	reRateDataQuery,
	availabilitiesDataQuery,
	needsDataQuery,
}

export {dashboardEntityAmountQuery, dashboardPortfolioAnalyticsQuery}
