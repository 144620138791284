export interface apiConfigInterface {
	protocol: string
	hostMPS: string
	hostMarketplace: string
	hostMDS: string
	hostPMS: string
	hostPBMS: string
	hostIAM: string
	hostConfig: string
	hostLocate: string
	hostALP: string
	hostNotification: string
	hostGateway: string
	hostGraphPMS: string
	hostGraphPBMS: string
	hostGraphMPS: string
	hostGraphMarketplace: string
	hostGraphMDS: string
	hostGraphIAM: string
	hostGraphConfig: string
	hostGraphLocate: string
	hostWSPMS: string
	hostWSMPS: string
	hostWSMP: string
	hostWSLocate: string
	hostWSAlp: string
	hostWSMna: string
	hostQuant: string
	flexmonsterKey: string
	agGridKey: string
}

export const apiConfig: apiConfigInterface = {
	protocol: process.env.REACT_APP_API_PROTOCOL,
	hostMPS: process.env.REACT_APP_MPS_API_HOSTNAME,
	hostMarketplace: process.env.REACT_APP_MARKETPLACE_API_HOSTNAME,
	hostMDS: process.env.REACT_APP_MDS_API_HOSTNAME,
	hostPMS: process.env.REACT_APP_PMS_API_HOSTNAME,
	hostPBMS: process.env.REACT_APP_PBMS_API_HOSTNAME,
	hostIAM: process.env.REACT_APP_IAM_API_HOSTNAME,
	hostConfig: process.env.REACT_APP_CONFIG_API_HOSTNAME,
	hostLocate: process.env.REACT_APP_LOCATE_API_HOSTNAME,
	hostALP: process.env.REACT_APP_ALP_API_HOSTNAME,
	hostNotification: process.env.REACT_APP_NOTIFICATION_API_HOSTNAME,
	hostGateway: process.env.REACT_APP_GATEWAY_API_HOSTNAME,
	hostGraphMPS: process.env.REACT_APP_MPS_GRAPH_API_HOSTNAME,
	hostGraphMarketplace: process.env.REACT_APP_MARKETPLACE_GRAPH_API_HOSTNAME,
	hostGraphMDS: process.env.REACT_APP_MDS_GRAPH_API_HOSTNAME,
	hostGraphPMS: process.env.REACT_APP_PMS_GRAPH_API_HOSTNAME,
	hostGraphPBMS: process.env.REACT_APP_PBMS_GRAPH_API_HOSTNAME,
	hostGraphIAM: process.env.REACT_APP_IAM_GRAPH_API_HOSTNAME,
	hostGraphConfig: process.env.REACT_APP_CONFIG_GRAPH_API_HOSTNAME,
	hostGraphLocate: process.env.REACT_APP_LOCATE_GRAPH_API_HOSTNAME,
	hostWSPMS: process.env.REACT_APP_PMS_WS_API_HOSTNAME,
	hostWSMP: process.env.REACT_APP_MP_WS_API_HOSTNAME,
	hostWSMPS: process.env.REACT_APP_MPS_WS_API_HOSTNAME,
	hostWSLocate: process.env.REACT_APP_LOCATE_WS_API_HOSTNAME,
	hostWSAlp: process.env.REACT_APP_ALP_WS_API_HOSTNAME,
	hostWSMna: process.env.REACT_APP_MNA_WS_API_HOSTNAME,
	hostQuant: process.env.REACT_APP_QUANT_API_HOSTNAME,
	flexmonsterKey: process.env.REACT_APP_FLEXMONSTER_LICENSE_KEY,
	agGridKey: process.env.REACT_APP_AG_GRID_LICENSE_KEY,
}
