import {buildQuery} from 'axios-graphql-builder'
import {getCounterpartyId} from '../../../services/authService'

export const getCounterpartyQuery = () => {
	const paramList = [{key: 'allClients', param: `tenantId: "${getCounterpartyId()}"`}]

	const counterparty = {
		query: {
			allClients: {
				id: null,
				clientId: null,
				fullName: null,
				shortName: null,
				clientType: null,
				agreementTypes: null,
				clientRiskType: null,
				contractTypes: null,
				legalEntities: {
					clientLegalEntityId: null,
					id: null,
					legalEntityId: null,
					clientRiskType: null,
				},
			},
		},
	}

	return buildQuery(counterparty, paramList)
}

export const getCollateralBasketQuery = () => {
	const collateralBasket = {
		query: {
			openCollateralBaskets: {
				collateralBasketId: null,
				name: null,
				notionalAmount: null,
				startCashAmount: null,
				book: null,
				counterParty: null,
				schedule: null,
			},
		},
	}

	return buildQuery(collateralBasket)
}

export const typeAheadResultQuery = (keyword = '', book, clientRiskType, isBidCollateralOffer) => {
	const paramList = [
		{
			key: 'securitySuggestions',
			param: `keyword: "${keyword}",schedule: null, book : ${book !== null ? `"${book}"` : null}, clientRiskType : ${
				clientRiskType !== null ? `"${clientRiskType}"` : null
			}, isBidCollateralOffer : ${isBidCollateralOffer !== null ? `${isBidCollateralOffer}` : null}`,
		},
	]

	const typeAhead = {
		query: {
			securitySuggestions: {
				securityId: null,
				cusip: null,
				ticker: null,
				assetType: null,
				assetSubtype: null,
				isin: null,
				securityDescription: null,
				availableQuantity: null,
				currency: null,
				cusipCode: null,
				margin: null,
				price: null,
				priceMultiplier: null,
				ric: null,
				schedule: null,
				scheduleDescription: null,
				sedol: null,
				minPiece: null,
				minIncrement: null,
				minLotSize: null,
			},
		},
	}

	return buildQuery(typeAhead, paramList)
}
