import {GET_MY_BU, SAVE_MY_BU, EDIT_MY_BU, CLEAR_BU_DATA} from './myBUActions'
import {MyBU} from './myBUModel'

export interface MyBUData {
	loading: boolean
	data: MyBU[]
}

const initialState: MyBUData = {
	loading: false,
	data: null,
}

export function myBUReducer(state = initialState, action: any) {
	let data: MyBU[] = []
	switch (action.type) {
		case GET_MY_BU.triggered:
			return {...state, loading: true}
		case GET_MY_BU.succeeded:
			data = (action.response.data && action.response.data.allBusinessUnits) || []
			return {...state, loading: false, data}
		case GET_MY_BU.failed:
			return {...state, loading: false}

		case SAVE_MY_BU.triggered:
			return {...state, loading: true}
		case SAVE_MY_BU.succeeded:
		case SAVE_MY_BU.failed:
			return {...state, loading: false}

		case EDIT_MY_BU.triggered:
			return {...state, loading: true}
		case EDIT_MY_BU.succeeded:
		case EDIT_MY_BU.failed:
			return {...state, loading: false}

		case CLEAR_BU_DATA:
			return {...state, data: null}
	}

	return state
}
